
import { useState } from "react";
import { Button, Upload, message, Modal } from 'antd';
import service from "@/services/axios";
import styles from './uploadFile.module.less';

interface UploadFileProps {
    successCallBack: (url: string) => void;
    icon?: string;
    btn_text?: string;
    accept_str?: string;
    isMultiple?: boolean;
    uploadProps?: any;
}

const UploadFile: React.FC<UploadFileProps> = ({
    successCallBack,
    icon,
    btn_text,
    accept_str,
    isMultiple,
    uploadProps,
}) => {
    // 两种状态，一种是图片按钮icon, 一种是普通文字按钮
    const [uploading, setUpLoading] = useState(false);

    const beforeUpload = async () => {
        setUpLoading(false);
    }
    const customRequest = async (options: any) => {
        setUpLoading(true)
        const data = new FormData();
        data.append('file', options.file);
        //根据上传的文件类型，如果是图片，上传到image文件夹，如果是音频，上传到audio文件夹，如果是文档，上传到doc文件夹
        let dir = 'image';
        if (options.file.type.indexOf('audio') !== -1) {
            dir = 'audio';
        } else if (options.file.type.indexOf('video') !== -1) {
            dir = 'video';
        } else if (options.file.type.indexOf('application') !== -1) {
            dir = 'doc';
        }
        const endpoint = `/uploadfile?dir=${encodeURIComponent(dir)}`
        try {
            const res: any = await service.post(endpoint, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (res.status === 'success') {
                message.success(`${options.file.name} uploaded successfully`);
                successCallBack(res.data.file_url);
                setUpLoading(false)
            }
        } catch (error) {
            message.error(`${options.file.name} file upload failed`);
            console.error(error);
        }
        setUpLoading(false)
    }
    const propsUpload = {
        accept: accept_str,
        beforeUpload,
        customRequest,
        //showUploadList: false,
        multiple: isMultiple,
        ...uploadProps,
    };

    //console.log('propsUpload', propsUpload);

    return (
        <>
            <Upload
                className={styles.uploadBtn}
                showUploadList={false}
                {...propsUpload}
            >
                {icon ? <img src={icon} /> : <Button>{btn_text}</Button>}
            </Upload>
            <Modal open={uploading} footer={null} closable={false}>
                <p>File is uploading...</p>
            </Modal>
        </>
    );
};

export default UploadFile;
