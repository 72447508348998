import { useState, useRef, useEffect } from "react";
import { useStores } from '@/store/useStores';
import { cloneDeep } from "lodash";
import { requestLLMCall } from '@/services/requestLLMCall';
import { containsVariablePattern, parseContent, rewriteText } from "@/utils/utils";
import service from '@/services/axios';
import useCommonFunction from '@/hooks/useCommonFunction';
import MultimediaAPI from "@/api/multimedia";
import { BlockTraceData } from "@/base/BlockData";
import { ElementData } from "@/base/ElementData/ElementData";
import AIChatRegionContent from "@/base/ElementData/AIChatRegionContent";
import { ChatMessage, MediaInfo } from "@/base/ChatMessage";
import LLMCallRequestData from "@/base/LLMCallRequestData";
import { genUUIDv4 } from "@/utils/id_generator";
import ImgGenLoading from '@/assets/img/playground/img_gen_loading.gif';

const useChat = (blockTraceData: BlockTraceData,
    elementData: ElementData<AIChatRegionContent>) => {
    // console.log('useChat elementData:', elementData, blockTraceData);
    const { lectureStore, userInfoStore, ttsStore, commonStatusStore } = useStores();
    const [inputValue, setInputValue] = useState(""); //用户输入的消息
    const [messages, setMessages] = useState(elementData?.content?.chatList || []); // 消息列表
    const [isFetching, setIsFetching] = useState(false); // Is fetching new messages
    const isTeacherView = userInfoStore.isTeacherView();
    const endMarkArr = ['.', '?', '!', ',', '，', '。', '？', '！', '、', ':', '：']; // 定义句子结束标记
    const isMountedRef = useRef(true);
    const { executeFunc } = useCommonFunction();

    //useEffect(() => {
    //    setMessages(elementData?.content?.chat_list || []);
    //}, [cloneDeep(elementData?.content?.chat_list)])

    useEffect(() => {
        //console.log("useEffect setMessages:", elementData?.content?.chat_list);
        setMessages(elementData?.content?.chatList || []);
    }, [elementData?.content?.chatList])


    //更新后端数据blockTrace
    const updateBlockTrace = async (newBlockTraceData: BlockTraceData) => {
        try {
            await service.put('/block_trace', newBlockTraceData.toJSON());
        } catch (error) {
            console.error('error', error)
        }
    }

    // 增加一个带填空功能的messahe
    const addChoiceMessage = (templateID: number, responseMethod: string,
        choiceStr: string, toolsStr: string, currentMsgIndex: number, descriptionAboutBlank: string = "",
        selectedChoice: any = null) => {
        // 这里generatePredefinedChoiceMsgData的最后一个参数先默认填true
        // 这里usedToSetVar是True
        const msg = ChatMessage.generatePredefinedChoiceMsgData(templateID, responseMethod, choiceStr, toolsStr, true, descriptionAboutBlank, selectedChoice);
        const newElementData = cloneDeep(elementData);
        newElementData.content.presetMessages.splice(currentMsgIndex + 1, 0, msg);
        // 这里做updateElement操作
        lectureStore.updateElement(blockTraceData.id, elementData.id, newElementData.content);
        return [...newElementData.content.presetMessages];
    };

    // 增加一个简单的predefined的message
    const addSimplePredefinedMessage = (message: string, responseMethod: string, toolsStr: string, currentMsgIndex: number) => {
        // 这里generatePredefinedChoiceMsgData的最后一个参数先默认填true
        // 这里usedToSetVar是True
        const msg = ChatMessage.generateSimplePredefinedMsgData(message, responseMethod, toolsStr);
        const newElementData = cloneDeep(elementData);
        newElementData.content.presetMessages.splice(currentMsgIndex + 1, 0, msg);
        // 这里做updateElement操作
        lectureStore.updateElement(blockTraceData.id, elementData.id, newElementData.content);
        return [...newElementData.content.presetMessages];
    };

    const repeatMessage = () => {
        // repeat the last message in preset_messages
        const newElementData = cloneDeep(elementData);
        const msg = cloneDeep(elementData.content.presetMessages[elementData.content.presetMessages.length - 1]);
        // remove selected_choice in blank_and_choices
        if (msg.blanks.length > 0) {
            msg.blanks.forEach(item => {
                if (item.selectedChoice) {
                    item.selectedChoice = '';
                }
            });
        }
        newElementData.content.presetMessages.push(msg);
        // 这里做updateElement操作
        lectureStore.updateElement(blockTraceData.id, elementData.id, newElementData.content);
        return [...newElementData.content.presetMessages];
    }

    const generateImage = async (drawPrompt: string) => {
        let vendor = "";
        if (import.meta.env.VITE_OSS_SITE === 'CloudFront') {
            vendor = "OpenAI";
        } else if (import.meta.env.VITE_OSS_SITE === 'Aliyun') {
            vendor = "ByteDance";
        }
        
        const api = new MultimediaAPI();

        let data = {
            request_id: "request_" + genUUIDv4(),
            prompt: drawPrompt,
            num_images: 1,
            directory: "ugc_images",
            quality: "standard",
            response_format: "b64_json",
            size: "1024x1024",
            style: "vivid",
            config: {
                "vendor": "ByteDance",
                "model": "high_aes_general_v21_L"
            }
        }

        console.log("draw call request", data);

        commonStatusStore.setIsFetching(true);
        const res: any = await api.imgGenRetry(data);
        if (res.status === 'success') {
            console.log('生成图片成功');
            commonStatusStore.setIsFetching(false);
            return res.data.file_url;
        } else {
            console.error('生成图片失败');
            commonStatusStore.setIsFetching(false);
            return null;
        }
    }

    const submitVideoTask = async (videoGenPrompt: string) => {
        let vendor = "";
        if (import.meta.env.VITE_OSS_SITE === 'AWS') {
            vendor = "OpenAI";
        } else if (import.meta.env.VITE_OSS_SITE === 'Aliyun') {
            // 视频模型还是先用zhipu
            vendor = "Zhipu";
        }

        const api = new MultimediaAPI();

        let data = {
            request_id: "request_" + genUUIDv4(),
            prompt: videoGenPrompt,
            // 目前是个简化版data
            config: {
                "vendor": "Zhipu"
            }
        }

        console.log("video gen call request", data);
        commonStatusStore.setIsFetching(true);
        const res: any = await api.videoGenSubmit(data);
        if (res.status === 'success') {
            console.log('视频任务提交成功');          
            return res.data.task_id;
        } else {
            console.error('生成视频失败');
            return null;
        }
    }


    // 发送消息
    // TODO： 这个function需要重构
    const handleSend = async (msg: ChatMessage | null, finishedPresetIndex: number,
        currentQuestionIndex: number) => {
        // console.log('handleSend:', msg, finishedPresetIndex, currentQuestionIndex, elementData.content.systemPrompt);
        if (isTeacherView) return;
        //let userInput = msg !== null ? msg : inputValue;
        // 如果还在等待回复，不能send
        if (isFetching) return;
        // 需要用户输入，但用户还没有输入，不能send
        if (msg === null && inputValue.trim() === "") return;

        //const userMessage = generateMessage(3, userInput, blanksAndChoices, setLectureVar);
        let userMessage: ChatMessage | null = null;
        if (msg !== null) {
            userMessage = msg;
            //需要set type
            userMessage.type = 3;
        } else {
            // 用户输入
            userMessage = new ChatMessage({
                type: 3,
                responseMethod: "gpt",
                rawContent: inputValue
            });
            msg = userMessage;
        }
        //做一个空消息
        // type 设为1，gpt的回答
        let gptMessage;
        // 这里需要判断chatTools中是否有draw，如果有，需要生成图片
        if (elementData.content.chatTools && elementData.content.chatTools.includes("draw")) {
            gptMessage = new ChatMessage(
                {
                    type: 4,
                    mediaInfo: new MediaInfo({ mediaType: "image", mediaUrl: ImgGenLoading }),
                });
        } else if (elementData.content.chatTools && elementData.content.chatTools.includes("video_generation")) {
            gptMessage = new ChatMessage(
                {
                    type: 4,
                    mediaInfo: new MediaInfo({ mediaType: "image", mediaUrl: ImgGenLoading }),
                });
        } else {
            gptMessage = new ChatMessage({ type: 1 });
        }


        let newMessages = []
        if (msg?.responseMethod === "preset") {
            newMessages = [...messages, userMessage];
        } else if (msg?.responseMethod === "gpt") {
            // 对于answerWay是gpt
            newMessages = [...messages, userMessage, gptMessage];
        } else if (msg?.responseMethod === "gpt-check") {
            if (elementData.content.chatTools && 
                (elementData.content.chatTools.includes("draw") || elementData.content.chatTools.includes("video_generation"))) {
                newMessages = [...messages, userMessage, gptMessage];
            } else {
                newMessages = [...messages, userMessage];
            }
        } else {
            newMessages = [...messages, userMessage, gptMessage];
        }
        setMessages(newMessages);
        ttsStore.clearAudioSegments();
        ttsStore.setUserNeedPause(false);
        setInputValue("");

        // 在lectureStore的learnedBlockList中找到当前block，并更新trace_info  
        const newChatItem = cloneDeep(elementData);
        newChatItem.content.chatList = newMessages;
        lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);

        if (newChatItem.content.mode === 1) {
            // 如果teacherChat， 我们先只更新lectureStore里的
            lectureStore.setTeacherChat(newChatItem.content);
        }

        //这里这条空消息应该已经子啊chat_list中了

        setIsFetching(true);  // 开始获取数据  
        commonStatusStore.setIsFetching(true);

        // 如果有 presetAnswer，直接使用它  
        if (msg?.responseMethod === "preset") {
            // preset这种不会有teacherChat
            const newElementData = cloneDeep(elementData);
            newElementData.content.chatList = newMessages;
            newElementData.content.finishedPresetIndex = finishedPresetIndex + 1;
            lectureStore.updateElement(blockTraceData.id,
                elementData.id, newElementData.content);

            setIsFetching(false);
            commonStatusStore.setIsFetching(false);
            //如果已经是最后一个问题，更新后端
            if (currentQuestionIndex === elementData.content.presetMessages.length - 1) {
                const newBlockTraceData = lectureStore.findBlockById(blockTraceData.id);
                updateBlockTrace(newBlockTraceData!);
            }
            return;
        }

        let systemPrompt: string = "";
        let tools: string[] = [];
        // 如果到了这里，answerWay是gpt或者gpt-check
        if (elementData.content.systemPrompt) {
            if (containsVariablePattern(elementData.content.systemPrompt)) {
                systemPrompt = rewriteText(elementData.content.systemPrompt);
                console.log('LLM 重写后的systemPrompt:', systemPrompt);
            } else {
                systemPrompt = elementData.content.systemPrompt;
            }
        }
        //console.log('requestParams:', requestParams);
        //console.log('elementData.content:', elementData.content.use_context);

        // 这里会check context的部分, 如果use_context为false了，去掉context
        // 如何是undefined，或者true，都会保留context  
        // 我们先把messages里带{{var}}的materialize一下 
        const messagesCopy = cloneDeep(messages);
        messagesCopy.forEach((msg) => {
            if (containsVariablePattern(msg.rawContent)) {
                msg.rawContent = rewriteText(msg.rawContent);
            }
        });
        const requestParams = LLMCallRequestData.genLLMCallParams(
            msg!.rawContent,
            userInfoStore.userInfoData.id,
            userInfoStore.userInfoData.name,
            msg!.stream,
            messagesCopy,
            systemPrompt,
            elementData.content.chatTools);

        console.log('requestParams after:', requestParams.toJSON());

        if (msg!.stream === false) {
            if (msg!.rawContent === '我觉得这个图像不错。' || msg!.rawContent === '我觉得这个视频不错。') {
                // 我们的模型判断错了
                // 这里直接return 
                setIsFetching(false);
                commonStatusStore.setIsFetching(false);
                newChatItem.content.chatList.pop();
                newChatItem.content.finishedPresetIndex = finishedPresetIndex + 1;
                lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);
                return;
            }
            // 这里是非流式调用，draw应该是这里
            const response: any = await requestLLMCall(requestParams);
            setIsFetching(false);
            commonStatusStore.setIsFetching(false);
            const res = await response.json();
            if (res.status === 'success') {
                //向chat_list中添加新的消息
                // 后端返回的结果还是得用snake cased
                if (res.data.tool_calls && res.data.tool_calls.length > 0) {
                    if (res.data.tool_calls[0].function === "draw") {
                        // 这里加个dirty fix
                        if (msg!.rawContent === '我觉得这个图像不错。') {
                            // 我们的模型判断错了
                            // 这里直接return 
                            return;
                        }


                        // 获取提示词
                        const drawPrompt = JSON.parse(res.data.tool_calls[0].arguments).content;
                        console.log('drawPrompt:', drawPrompt);
                        const imgUrl = await generateImage(drawPrompt);
                        //const newMsgs = addMediaMessage("image", imgUrl, newChatItem, finishedPresetIndex+1);
                        //console.log('newMsgs:', newMsgs);
                        //const msgData = generateMediaMsgData("image", imgUrl);
                        const mediaMsg = new ChatMessage({
                            type: 4,
                            rawContent: "",
                            mediaInfo: new MediaInfo({ mediaType: "image", mediaUrl: imgUrl })
                        });
                        // 有个placeholder的msg需要去掉
                        newChatItem.content.chatList.pop();
                        newChatItem.content.chatList.push(mediaMsg);
                        //if (newMsgs !== null) {
                        //    newChatItem.content.preset_messages = newMsgs;
                        //}
                        newChatItem.content.finishedPresetIndex = finishedPresetIndex + 1;
                        // 这里做updateElement操作
                        lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);

                        if (newChatItem.content.mode === 1) {
                            // 如果teacherChat， 我们先只更新lectureStore里的
                            lectureStore.setTeacherChat(newChatItem.content);
                        }

                        // 这里还需要看之前的问题小时是gpt还是gpt-check, 如果是gpt-check， 需要生成一个choice的消息
                        if (msg!.responseMethod === "gpt-check") {
                            const toolList = msg!.tools!.split(',');
                            if (toolList.includes("local_try_check")) {
                                const newPresetMsgs = addChoiceMessage(1, "gpt-check", "不错 | 需要再生成一张看看", "local_try_check", finishedPresetIndex);
                                newChatItem.content.presetMessages = newPresetMsgs;
                                newChatItem.content.finishedPresetIndex = finishedPresetIndex + 1;
                                // 这里做updateElement操作
                                lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);
                                // TODO: teacherChat暂时没有gpt-check这种
                            }

                        }

                    }
                    else if(res.data.tool_calls[0].function === "video_generation") {
                        if (msg!.rawContent === '我觉得这个视频不错。') {
                            // 这里直接return 
                            return;
                        }

                        // 获取提示词
                        const videoGenPrompt = JSON.parse(res.data.tool_calls[0].arguments).content;
                        console.log('videGenPrompt:', videoGenPrompt);
                        const taskId = await submitVideoTask(videoGenPrompt);

                        if(taskId === null) {
                            console.log("视频任务提交失败");
                        } else {                            
                            // 这里需要一个轮询，直到生成成功，500秒
                            const maxTries = 500;
                            let i = 0;
                            while (i < maxTries) {
                                const api = new MultimediaAPI();
                                const resp: any = await api.getGenVideo(taskId);
                                if(resp.status === 'success') {
                                    console.log('视频生成成功', resp);
                                    const mediaMsg = new ChatMessage({
                                        type: 4,
                                        rawContent: "",
                                        mediaInfo: new MediaInfo({ mediaType: "video", mediaUrl: resp.file_url })
                                    });

                                    // 有个placeholder的msg需要去掉
                                    newChatItem.content.chatList.pop();
                                    newChatItem.content.chatList.push(mediaMsg);
                    
                                    newChatItem.content.finishedPresetIndex = finishedPresetIndex + 1;
                                    // 这里做updateElement操作
                                    lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);
                                    updateBlockTrace(lectureStore.findBlockById(blockTraceData.id)!);

                                    if (newChatItem.content.mode === 1) {
                                        // 如果teacherChat， 我们先只更新lectureStore里的
                                        lectureStore.setTeacherChat(newChatItem.content);
                                    }

                                    // 这里还需要看之前的问题小时是gpt还是gpt-check, 如果是gpt-check， 需要生成一个choice的消息
                                    if (msg!.responseMethod === "gpt-check") {
                                        const toolList = msg!.tools!.split(',');
                                        if (toolList.includes("local_try_check")) {
                                            const newPresetMsgs = addChoiceMessage(1, "gpt-check", "不错 | 需要再生成一个视频", "local_try_check", finishedPresetIndex);
                                            newChatItem.content.presetMessages = newPresetMsgs;
                                            newChatItem.content.finishedPresetIndex = finishedPresetIndex + 1;
                                            // 这里做updateElement操作
                                            lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);
                                            // TODO: teacherChat暂时没有gpt-check这种
                                        }

                                    }
                                    // 结束 
                                    break;

                                } else {
                                    i++;
                                    // sleep 1s
                                    await new Promise(r => setTimeout(r, 1000));
                                }
                            }
                            commonStatusStore.setIsFetching(false);
                        }
                    }
                }              
            }
            return;
        }
        // 向后端发送POST请求, 如果到这里，必然是stream为true的情况 
        const response: any = await requestLLMCall(requestParams);
        if (!response.ok) {
            console.error('请求失败，状态码为：' + response.status);
            return;
        }

        console.log('LLM 请求成功');
        const reader = response.body.getReader();
        // 处理后端的流响应  
        await processLLMStreamResponse(reader, newMessages, msg!, finishedPresetIndex);

        setIsFetching(false);
        commonStatusStore.setIsFetching(false);
    };


    // 其实是处理LLM流式响应  
    const processLLMStreamResponse = async (reader: any, messages: ChatMessage[],
        requestMsg: ChatMessage, finishedPresetIndex: number) => {
        let responseTextBuffer = '';
        console.log('processLLMResponse:');
        // 因为上面逻辑修改，这里需要插入一条placeholder消息
        // 只在gpt-check的情况下插入
        if (requestMsg.responseMethod === "gpt-check") {
            const gptMessage = new ChatMessage({ type: 1 });
            messages.push(gptMessage);
        }

        try {
            // 这里一般用的都是llmcallV1
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    if (finishedPresetIndex !== undefined) {

                        console.log('流数据读取完毕:', messages);
                        let newPresetMsgs: ChatMessage[] | null = null;
                        if (requestMsg.responseMethod === "gpt-check") {
                            console.log('requestMsg', requestMsg);
                            const toolList = requestMsg.tools!.split(',');
                            if (toolList.includes("choice_check")) {
                                // prepare a special choice_check
                                const selectedMsg = messages[messages.length - 1].rawContent;
                                if (selectedMsg === "我选重新生成。") {
                                    console.log('重新生成');
                                }

                                const requestParams = LLMCallRequestData.genLLMCallParams(
                                    selectedMsg,
                                    userInfoStore.userInfoData.id,
                                    userInfoStore.userInfoData.name,
                                    false, // non-streaming call
                                    [],
                                    "",
                                    requestMsg.tools!
                                );

                                const response: any = await requestLLMCall(requestParams);
                                //setIsFetching(false);
                                //commonStatusStore.setIsFetching(false);
                                const res = await response.json();
                                if (res.status === 'success' && res.data.tool_calls && res.data.tool_calls.length > 0) {
                                    // TODO：目前这段逻辑和试听课紧耦合了，以后得想办法优化
                                    const resJson = JSON.parse(res.data.tool_calls[0].arguments);
                                    const choices = resJson.choices;
                                    const hasChosenNickname = resJson.has_chosen_nickname;
                                    const hasChosenTagLine = resJson.has_chosen_tagline;

                                    if (!hasChosenNickname && !hasChosenTagLine) {
                                        // 提供一个再来一次的选项
                                        const optionStr = choices.join(" | ") + " | 重新生成";

                                        newPresetMsgs = addChoiceMessage(0, "gpt-check", optionStr, "choice_check", finishedPresetIndex + 1);
                                    }
                                }
                            }
                            if (toolList.includes("writing_response_check")) {
                                console.log('we are in writing_response_check');
                                const prevMsg = messages[messages.length - 1].rawContent;
                                console.log('prevMsg', prevMsg);
                                const requestParams = LLMCallRequestData.genLLMCallParams(
                                    prevMsg,
                                    userInfoStore.userInfoData.id,
                                    userInfoStore.userInfoData.name,
                                    false, // non-streaming call
                                    [],
                                    "",
                                    "writing_response_check"
                                );
                                const response: any = await requestLLMCall(requestParams);
                                //setIsFetching(false);
                                //commonStatusStore.setIsFetching(false);
                                const res = await response.json();
                                if (res.status === 'success' && res.data.tool_calls && res.data.tool_calls.length > 0) {
                                    // TODO：目前这段逻辑和试听课紧耦合了，以后得想办法优化
                                    const resJson = JSON.parse(res.data.tool_calls[0].arguments);
                                    console.log('resJson:', resJson);
                                    const askedIfSatisfied = resJson.asked_if_satisfied;
                                    const keypoint = resJson.key_point_to_write;
                                    const idxOfKeypoint = resJson.idx_key_point_to_write;
                                    const askedToReorg = resJson.asked_to_reorganize;
                                    const readyToStep5 = resJson.ready_to_enter_step5;

                                    if (askedIfSatisfied) {
                                        // 提供一个再来一次的选项
                                        console.log('askedIfSatisfied', askedIfSatisfied);
                                        const optionStr = "我满意 | 不太满意，请重新生成";
                                        newPresetMsgs = addChoiceMessage(2, "gpt-check", optionStr, "writing_response_check", finishedPresetIndex + 1, "", "我满意");
                                    } else if (idxOfKeypoint !== null && idxOfKeypoint !== '') {
                                        console.log('keypoint', keypoint, idxOfKeypoint);
                                        let descContent = "";
                                        if (keypoint !== null && keypoint !== '') {
                                            descContent = "请根据第" + idxOfKeypoint + "个要点：" + keypoint + "写几句话";
                                        } else {
                                            descContent = "请根据第" + idxOfKeypoint + "个要点写几句话";
                                        }
                                        newPresetMsgs = addChoiceMessage(3, "gpt-check", "", "writing_response_check", finishedPresetIndex + 1, descContent);
                                        console.log('newPresetMsgs:', newPresetMsgs);
                                    } else if (askedToReorg) {
                                        console.log('askedToReorg', askedToReorg);
                                        const optionStr = "整理成300字 | 整理成400字 | 扩写到700字";
                                        newPresetMsgs = addChoiceMessage(2, "gpt-check", optionStr, "writing_response_check", finishedPresetIndex + 1, "", "整理成300字");
                                        console.log('newPresetMsgs:', newPresetMsgs);
                                    } else if (readyToStep5) {
                                        console.log('readyToStep5', readyToStep5);
                                        //const optionStr =  "我准备好了";
                                        //newPresetMsgs = addChoiceMessage(2, "gpt-check", optionStr, "writing_response_check", finishedPresetIndex + 1, "", "我准备好了");
                                        newPresetMsgs = addSimplePredefinedMessage("请给这篇文章提些建议。", "gpt-check", "writing_response_check", finishedPresetIndex);
                                        console.log('newPresetMsgs:', newPresetMsgs);
                                    }
                                }
                            }

                        }

                        const newChatItem = cloneDeep(elementData);
                        newChatItem.content.chatList = messages;

                        // 这里处理repeat preset msg的情况，这样情况目前都是gpt流式回答
                        if (requestMsg.shouldRepeatPreset) {
                            newPresetMsgs = repeatMessage();
                            console.log('newPresetMsgs after repeat:', newPresetMsgs);
                        }

                        if (newPresetMsgs !== null) {
                            newChatItem.content.presetMessages = newPresetMsgs;
                            console.log('newChatItem', newChatItem.content);
                        }
                        newChatItem.content.finishedPresetIndex = finishedPresetIndex + 1;
                        // 这里做updateElement操作
                        lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);

                        if (newChatItem.content.mode === 1) {
                            // 如果teacherChat， 我们先只更新lectureStore里的
                            lectureStore.setTeacherChat(newChatItem.content);
                        }

                    }
                    if (requestMsg.doneCallFunc?.funcName !== undefined && requestMsg.doneCallFunc?.funcName !== null) {
                        if (requestMsg.doneCallFunc.funcName === "changeTeacherWords") {
                            commonStatusStore.setIsTeacherSpeaking(true);
                        }
                        executeFunc(requestMsg.doneCallFunc?.funcName, requestMsg.doneCallFunc?.funcParams);
                    }

                    if (responseTextBuffer.length > 0 && elementData.content.shouldDoTts && !ttsStore.userNeedPause) {
                        await textToSpeech(responseTextBuffer);
                    }

                    let newBlockTraceData = lectureStore.findBlockById(blockTraceData.id);
                    updateBlockTrace(newBlockTraceData!);
                    responseTextBuffer = ''; // 清空  
                    break;
                }

                let str = new TextDecoder("utf-8").decode(value);
                let parseStr = parseContent(str);
                messages[messages.length - 1].rawContent += parseStr;
                responseTextBuffer += parseStr;
                setMessages(cloneDeep(messages));


                const newChatItem = cloneDeep(elementData);
                newChatItem.content.chatList = messages;
                lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);

                if (newChatItem.content.mode === 1) {
                    // 如果teacherChat， 我们先只更新lectureStore里的
                    lectureStore.setTeacherChat(newChatItem.content);
                }

                while (hasEnoughCharacters(responseTextBuffer)) {
                    let endMarkIndex = responseTextBuffer.length;
                    for (let i = 0; i < endMarkArr.length; i++) {
                        let index = responseTextBuffer.indexOf(endMarkArr[i]);
                        if (index !== -1 && index < endMarkIndex) {
                            endMarkIndex = index;
                        }
                    }
                    let group = responseTextBuffer.slice(0, endMarkIndex + 1);
                    responseTextBuffer = responseTextBuffer.slice(endMarkIndex + 1);
                    if (isMountedRef.current && elementData.content.shouldDoTts && !ttsStore.userNeedPause) {
                        await textToSpeech(group);
                    }
                }
            }

        } catch (error) {
            console.error('读取流数据时出错：', error);
        }
    };

    const hasEnoughCharacters = (responseTextBuffer: string) => {
        // return responseTextBuffer.length >= 10 ? true : false;
        return endMarkArr.some((endMark) => responseTextBuffer.includes(endMark)); // 判断是否有句子结束
    }

    const textToSpeech = async (text: string) => {
        const ttsParams = {
            text: text,
            // 默认 灿灿
            voice_type: elementData.content.ttsVoice ? elementData.content.ttsVoice : "BV700_streaming",
        }
        try {
            const res: any = await service.post('/tts', ttsParams);
            if (res.status === 'success' && res.data !== null) {
                const audioSegment = res.data;
                if (isMountedRef.current) {
                    if (ttsStore.userNeedPause) return;
                    ttsStore.addAudioSegment(audioSegment);
                }
            }
        } catch (error) {
            console.log('error:', error);
        }
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            // user input message
            handleSend(null, -1, -1);
        }
    };

    useEffect(() => {
        commonStatusStore.setIsFetching(false);
        return () => {
            isMountedRef.current = false;
            commonStatusStore.setIsFetching(false);
        };
    }, []);

    return { inputValue, setInputValue, messages, setMessages, handleSend, handleKeyDown };
};
export default useChat;