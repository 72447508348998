import React, { useState, useMemo, useRef, useEffect } from 'react';
import styles from "./neuronCalculatorRegion.module.less"
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import NeuronCalculatorRegionContent from '@/base/ElementData/NeuronCalculatorRegionContent';
import PlusIcon from '@/assets/img/plus_icon.svg';
import MinusSignIcon from '@/assets/img/minus_sign_icon.svg';
import BiasAdjustIcon from '@/assets/img/neuron_adjust_icon.svg';
import UnlitBulbImg from '@/assets/img/bulb_unlit.svg';
import LitBulbImg from '@/assets/img/bulb_lit.svg';

const NeuronCalculatorRegion: React.FC<IntrinsicElementProps<NeuronCalculatorRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
}) => {
    const [x1, setX1] = useState<number>(0);
    const [x2, setX2] = useState<number>(0);
    const [w1, setW1] = useState<number>(0.1);
    const [w2, setW2] = useState<number>(0.1);
    const [bias, setBias] = useState<number>(10);
    const [isDraggingBias, setIsDraggingBias] = useState(false);
    const sliderRefBias = useRef<HTMLDivElement>(null);

    const y = useMemo(() => x1 * w1 + x2 * w2, [x1, x2, w1, w2]);

    const inputRef1 = useRef<HTMLInputElement>(null);
    const inputRef2 = useRef<HTMLInputElement>(null);
    const calculatorBoxRef = useRef<HTMLDivElement>(null);

    const [isMounted, setIsMounted] = useState(false);

    const renderInputLine = (fromEl: HTMLInputElement | null, isRight: boolean) => {
        if (fromEl && calculatorBoxRef.current) {
            const fromRect = fromEl.getBoundingClientRect();
            const toEl = calculatorBoxRef.current.getBoundingClientRect();
            const parentRect = fromEl.parentElement?.getBoundingClientRect();
            if (!parentRect) return null;
            let startX;
            // 根据 isRight 参数确定起始点是右侧中点还是左侧中点
            if (isRight) {
                startX = fromRect.right - parentRect.left; // 从右侧中点
            } else {
                startX = fromRect.left - parentRect.left; // 从左侧中点
            }
            const startY = fromRect.top + fromRect.height / 2 - parentRect.top; // 中间
            // 结束位置的计算
            let endX;
            const endY = toEl.bottom - parentRect.top; // 下边界
            if (isRight) {
                endX = toEl.left + toEl.width * 0.4 - parentRect.left; // 下边界偏左一点
            } else {
                endX = toEl.left + toEl.width * 0.6 - parentRect.left; // 下边界偏右一点
            }
            const lineLength = Math.hypot(endX - startX, endY - startY) + 25;
            const angle = Math.atan2(endY - startY, endX - startX) * 180 / Math.PI;
            return (
                <div
                    style={{
                        position: 'absolute',
                        left: startX,
                        top: startY,
                        width: lineLength,
                        height: isRight ? `${w1 * 2}rem` : `${w2 * 2}rem`,
                        borderRadius: "1rem",
                        backgroundColor: '#28E4A2',
                        transform: `rotate(${angle}deg)`,
                        transformOrigin: `0 50%`,
                    }}
                    className={styles.lineConnector}
                />
            );
        }
        return null;
    };

    const changeWeight = (isW1: boolean, isAdd: boolean) => {
        // 0.1 为步长,范围是 0 - 1
        if (isW1) {
            setW1((prev) => {
                if (isAdd) {
                    return Math.min(prev + 0.1, 1);
                }
                return Math.max(prev - 0.1, 0.1);
            });
        } else {
            setW2((prev) => {
                if (isAdd) {
                    return Math.min(prev + 0.1, 1);
                }
                return Math.max(prev - 0.1, 0.1);
            });
        }
    }

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const NumberSelector: React.FC<{
        value: number;
        onChange: (value: number) => void;
        isReverse: boolean; // 新增参数，控制高亮方向
    }> = ({ value, onChange, isReverse }) => {
        const numbers = [...Array(10)].map((_, index) => index + 1);
        if (isReverse) {
            numbers.reverse();
        }
        return (
            <div className={styles.numberSelector}>
                {numbers.map((num) => {
                    return (
                        <div
                            key={num}
                            className={`${styles.numberBlock} ${value / 2 >= num ? styles.active : ''}`}
                            onClick={(e) => {
                                e.stopPropagation(); // 阻止事件冒泡
                                onChange(value / 2 === num ? 0 : num * 2);
                            }}
                            title={`选择 ${num}`}
                        >
                            {/* 小长方形 */}
                        </div>
                    );
                })}
            </div>
        );
    };

    // 处理bias拖动
    const handleBiasDown = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        setIsDraggingBias(true);
    };

    const handleBiasMove = (e: any) => {
        if (isDraggingBias && sliderRefBias.current) {
            const boundingRect = sliderRefBias.current.getBoundingClientRect();
            const clientX = e.touches ? e.touches[0].clientX : e.clientX;
            let offsetX = clientX - boundingRect.left;
            offsetX = Math.max(0, Math.min(offsetX, boundingRect.width));

            const newBias = (offsetX / boundingRect.width) * 20; // 0-20范围
            setBias(Math.round(newBias * 10) / 10); // 保留一位小数
        }
    };

    const handleBiasUp = () => {
        setIsDraggingBias(false);
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleBiasMove);
        document.addEventListener('mouseup', handleBiasUp);
        document.addEventListener('touchmove', handleBiasMove);
        document.addEventListener('touchend', handleBiasUp);

        return () => {
            document.removeEventListener('mousemove', handleBiasMove);
            document.removeEventListener('mouseup', handleBiasUp);
            document.removeEventListener('touchmove', handleBiasMove);
            document.removeEventListener('touchend', handleBiasUp);
        };
    }, [isDraggingBias]);

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle, position: 'relative' }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.weightedCalculatorBox}>
                    <div className={styles.topBox}>
                        <div className={styles.bulbBox}>
                            <img src={
                                y >= bias ? LitBulbImg : UnlitBulbImg
                            } alt="bulb" className={styles.bulb} />
                        </div>
                        <div className={styles.calculatorBox} ref={calculatorBoxRef}>
                            <div
                                className={styles.resultBarBox}
                                ref={sliderRefBias}
                                onMouseDown={handleBiasDown}
                                onTouchStart={handleBiasDown}
                            >
                                <div className={styles.box1}>
                                    <div
                                        className={styles.resultBar}
                                        style={{ width: `${y / 20 * 100}%` }}
                                    >
                                    </div>
                                </div>
                                <div className={styles.box2}>
                                    <img
                                        src={BiasAdjustIcon}
                                        alt="bias"
                                        className={styles.biasAdjust}
                                        style={{ left: `calc(${bias / 20 * 100}% - 1.5rem)` }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={styles.inputWBox}>
                        <div className={styles.inputW}>
                            <div className={styles.add} onClick={() => changeWeight(true, true)}>
                                <img src={PlusIcon} alt="plus" />
                            </div>
                            <div className={styles.minus} onClick={() => changeWeight(true, false)}>
                                <img src={MinusSignIcon} alt="minus" />
                            </div>
                        </div>
                        <div className={styles.inputW}>
                            <div className={styles.add} onClick={() => changeWeight(false, true)}>
                                <img src={PlusIcon} alt="plus" />
                            </div>
                            <div className={styles.minus} onClick={() => changeWeight(false, false)}>
                                <img src={MinusSignIcon} alt="minus" />
                            </div>
                        </div>

                    </div>
                    {/* 输入区域 */}
                    <div className={styles.inputXBox}>
                        <div className={styles.inputX1} ref={inputRef1}>
                            <NumberSelector value={x1} onChange={setX1} isReverse={false} />
                            {isMounted && renderInputLine(inputRef1.current, true)}
                        </div>
                        <div className={styles.inputX2} ref={inputRef2}>
                            <NumberSelector value={x2} onChange={setX2} isReverse={true} />
                            {isMounted && renderInputLine(inputRef2.current, false)}
                        </div>
                    </div>
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default NeuronCalculatorRegion;