import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores'
import cloneDeep from 'lodash/cloneDeep';
import MultimediaAPI from '@/api/multimedia';
import styles from './messageInputAndSend.module.less'
import { Blank, ChatMessage } from '@/base/ChatMessage';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import { message } from 'antd';
//import mixpanel from 'mixpanel-browser';
import CancelIcon from '@/assets/img/chat_component/cancel_fill_in_blank.svg'
import SendDisableIcon from '@/assets/img/chat_component/send_disable.svg';
import SendAbleIcon from '@/assets/img/chat_component/send_able.svg';
import autosize from 'autosize';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import KeyboardIcon from '@/assets/img/chat_component/keyboard.jpeg';
import BlankMicrophoneIcon from '@/assets/img/chat_component/microphone.jpeg';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import AudioPlayingIcon from '@/assets/img/chat_component/audio_playing.svg';
import StopAudioIcon from '@/assets/img/chat_component/stop_audio.svg';
import PlayAudioIcon from '@/assets/img/chat_component/play_input_message.svg';
import GenAudioLoading from '@/assets/img/chat_component/gen_audio_loading.svg';
import UploadFileIcon from '@/assets/img/playground/chat_link.svg';
import UploadFile from '../UploadFile/UploadFile';

interface MessageInputAndSendProps {
    msg: ChatMessage;
    handleSend: (
        msg: ChatMessage,
        finishedPresetIndex: number,
        currentQuestionIndex: number,
    ) => void;
    elementDataId: number;
    shouldReadQuestion: boolean;
    currentPlayingPresetId: number;
    setCurrentPlayingPresetId: (id: number) => void;
    finishedPresetIndex?: number;
    currentQuestionIndex?: number;
    setCurrentPresetQuestion?: (question: any) => void;
    userMessageAvatar?: string;
}

const MessageInputAndSend: React.FC<MessageInputAndSendProps> = ({
    msg,
    handleSend,
    elementDataId,
    shouldReadQuestion,
    currentPlayingPresetId,
    setCurrentPlayingPresetId,
    finishedPresetIndex,
    currentQuestionIndex,
    setCurrentPresetQuestion,
    userMessageAvatar,
}) => {
    const ttsAPI = new MultimediaAPI();
    const { commonStatusStore, audioStore, lectureStore, userInfoStore } = useStores();
    const isComponentMounted = useRef<boolean | null>(null);
    const [blanks, setBlanks] = useState<Blank[]>(cloneDeep(msg?.blanks) || []);
    const [selectedBlankIndex, setSelectedBlankIndex] = useState<number | null>((msg?.blanks && msg?.blanks.length > 0) ? 0 : null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [finalMsgToSend, setFinalMsgToSend] = useState<ChatMessage>();
    const [choiceAreaVisible, setChoiceAreaVisible] = useState(false);
    const [isCustomInputVisible, setIsCustomInputVisible] = useState(false);
    const [isComposing, setIsComposing] = useState(false); // 是否正在拼音输入
    const [uploadFileAcceptStr, setUploadFileAcceptStr] = useState<string>('');

    // 播放音频相关
    const [isPlayingMessage, setIsPlayingMessage] = useState(false);
    const [isHoveredAudioPlayingIcon, setIsHoveredAudioPlayingIcon] = useState(false);
    const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef<HTMLAudioElement>(new Audio());
    const [ttsVoice, setTtsVoice] = useState<string | null>(null);

    //当前msg中是否存在填空项
    const [hasBlank, setHasBlank] = useState(false);

    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const customInputRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (textareaRef.current) {
            autosize(textareaRef.current);
        }
        setIsCustomInputVisible(false);

        return () => {
            if (textareaRef.current) {
                autosize.destroy(textareaRef.current);
            }
        };
    }, [finalMsgToSend]);

    useEffect(() => {
        // 监控 finalMsgToSend 的变化并更新 autosize  
        if (textareaRef.current) {
            textareaRef.current.value = finalMsgToSend?.rawContent || '';
            autosize.update(textareaRef.current);
        }
    }, [finalMsgToSend]);

    useEffect(() => {
        if (msg) {
            setSelectedBlankIndex((msg?.blanks && msg?.blanks.length > 0) ? 0 : null);
            const backupMsg = cloneDeep(msg);
            const hasBlank = backupMsg?.blanks && backupMsg?.blanks.length > 0;
            setHasBlank(hasBlank);
            if (containsVariablePattern(backupMsg.rawContent)) {
                backupMsg.rawContent = rewriteText(backupMsg.rawContent);
            }
            setFinalMsgToSend(backupMsg);
            //根据msg中的uploadFileType设置accept_str
            if (msg.uploadFileType) {
                if (msg.uploadFileType === 'image') {
                    setUploadFileAcceptStr('image/*');
                } else if (msg.uploadFileType === 'audio') {
                    setUploadFileAcceptStr('audio/*');
                } else if (msg.uploadFileType === 'video') {
                    setUploadFileAcceptStr('video/mp4');
                } else if (msg.uploadFileType === 'doc') {
                    setUploadFileAcceptStr('.txt,.docx');
                }
            }
        } else {
            setFinalMsgToSend(new ChatMessage({
                type: 3,
                responseMethod: "gpt",
                rawContent: "",
                userCanEditRawContent: true,
            }));
            setHasBlank(false);
        }
    }, [msg])

    const handleClick = (index: number) => {
        setSelectedBlankIndex(index);
        setChoiceAreaVisible(true);
        if (index !== selectedBlankIndex) {
            setIsCustomInputVisible(false);
        }
    };

    // 关闭选项区域
    const closeChoiceArea = () => {
        setChoiceAreaVisible(false);
    }

    //点击选项区域之外的区域关闭选项区域
    const handleClickOutside = (e: MouseEvent) => {
        if (choiceAreaVisible && e.target instanceof Element && !e.target.closest(`.${styles.choicesArea}`)) {
            closeChoiceArea();
            setIsCustomInputVisible(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, [choiceAreaVisible])

    const handleOptionClick = (choice: string) => {
        setIsCustomInputVisible(false);
        console.log("handleOptionClick", choice, blanks);
        const newBlanks = [...blanks];
        newBlanks[selectedBlankIndex!].selectedChoice = choice;
        msg.blanks = newBlanks;
        setBlanks(newBlanks);
    };

    const choiceInputChange = (e: React.FormEvent<HTMLDivElement>) => {
        // 只有在非拼音输入状态时才进行更新  
        if (!isComposing) {
            const target = e.currentTarget;
            const caretPosition = getCaretPosition(target);  // 获取光标位置  
            const newBlanks = [...blanks];
            newBlanks[selectedBlankIndex!].selectedChoice = e.currentTarget.textContent || '';

            // 更新选项  
            msg.blanks = newBlanks;
            setBlanks(newBlanks);

            // 让光标保持在原有位置  
            setTimeout(() => {
                setCaretPosition(target, caretPosition);  // 恢复光标位置  
            }, 0);
        }
    };

    // 获取光标位置  
    const getCaretPosition = (editableDiv: HTMLDivElement) => {
        const selection = window.getSelection();
        let position = 0;
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            position = range.endOffset;
        }
        return position;
    };

    // 设置光标位置  
    const setCaretPosition = (editableDiv: HTMLDivElement, position: number) => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.setStart(editableDiv.childNodes[0], position);
        range.collapse(true);
        selection?.removeAllRanges();
        selection?.addRange(range);
    };



    // 当用户开始拼音输入时  
    const handleCompositionStart = () => {
        setIsComposing(true);
    };

    // 当用户结束拼音输入时  
    const handleCompositionEnd = (e: React.CompositionEvent<HTMLDivElement>) => {
        setIsComposing(false);
        const target = e.currentTarget;
        const caretPosition = getCaretPosition(target);  // 获取光标位置  

        // 处理选择的文本  
        const newBlanks = [...blanks];
        newBlanks[selectedBlankIndex!].selectedChoice = target.textContent || '';
        msg.blanks = newBlanks;
        setBlanks(newBlanks);

        // 设定光标位置  
        setTimeout(() => {
            setCaretPosition(target, caretPosition);  // 恢复光标位置  
        }, 0);
    };

    const clickKeyBoardIcon = () => {
        setIsCustomInputVisible(true);
        setTimeout(() => {
            if (customInputRef.current) {
                customInputRef.current.focus();  // 在点击键盘图标后聚焦  
            }
        }, 0);
        //清除当前选项的selectedChoice
        const newBlanks = [...blanks];
        // newBlanks[selectedBlankIndex!].selectedChoice = '';
        msg.blanks = newBlanks;
        setBlanks(newBlanks);
    }

    const cancelFillBlank = (index: number) => {
        const newBlanks = [...blanks];
        newBlanks[index].selectedChoice = null;
        msg.blanks = newBlanks;
        setBlanks(newBlanks);
    }

    const renderChoices = (choicesStr: string) => {
        return choicesStr.split(/\|/).map((option, index) => (
            <div
                key={index}
                onClick={() => handleOptionClick(option.trim())}
                className={styles.choiceItem}
            >
                <div
                    className={styles.choiceText}
                    style={option.trim() === blanks[selectedBlankIndex!]?.selectedChoice ? { opacity: "30%" } : {}}
                >
                    {option.trim()}
                </div>
            </div>
        ));
    }

    const renderText = (msg: ChatMessage, msgContent: string) => {
        if (blanks.length === 0) {
            // 如果没有填空
            return (
                <ReactMarkdown
                    className={styles.markdown}
                    remarkPlugins={[remarkBreaks]}
                >
                    {msgContent}
                </ReactMarkdown>
            );
        }

        let renderedText = [];
        let lastIndex = 0;

        msg?.blanks.forEach((blank, index) => {
            const { start, end, selectedChoice } = blank;
            const isCurrentBlankEmpty = selectedBlankIndex === index && !selectedChoice;

            renderedText.push(msg.rawContent.substring(lastIndex, start));
            renderedText.push(
                <div
                    key={index}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick(index);
                    }}
                    className={`${styles.blank} ${selectedBlankIndex === index ? styles.selectedBlank : ''}`}
                >
                    <div className={`${isCurrentBlankEmpty && !isCustomInputVisible ? styles.blink : ''} ${styles.blankInside}`}>
                        {
                            isCustomInputVisible && selectedBlankIndex === index ? (
                                <div
                                    ref={customInputRef}
                                    contentEditable
                                    className={styles.customInput}
                                    onInput={(e) => choiceInputChange(e)}
                                    onCompositionStart={handleCompositionStart} // 添加  
                                    onCompositionEnd={handleCompositionEnd} // 添加   
                                    suppressContentEditableWarning={true} // React的警告 
                                >
                                    {(blank.selectedChoice && containsVariablePattern(blank.selectedChoice) ? rewriteText(blank.selectedChoice) : blank.selectedChoice) || ''}
                                </div>
                            ) : (
                                ((blank.selectedChoice && containsVariablePattern(blank.selectedChoice) ? rewriteText(blank.selectedChoice) : blank.selectedChoice) ||
                                    <span className={styles.descriptionAboutBlank}>
                                        {blank.descriptionAboutBlank ? blank.descriptionAboutBlank : '\u00A0'.repeat(end - start + 1)}
                                    </span>
                                ))
                        }
                        {blank.selectedChoice && (
                            <img
                                src={CancelIcon} // 这里使用你实际的图片路径
                                alt="cancel"
                                className={styles.cancelIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    cancelFillBlank(index);
                                }}
                            />
                        )}
                    </div>
                </div>
            );
            lastIndex = end + 1;
        });

        renderedText.push(msgContent?.substring(lastIndex));
        return renderedText;
    };


    const sendPresetQuestion = (msg: ChatMessage) => {
        console.log("sendPresetQuestion", msg);
        //如果语音合成中，或者正在播放音频，不允许发送
        if (isGeneratingAudio || isPlayingMessage) {
            message.error('正在播放或生成音频，请稍后再试');
            return;
        }
        if (msg.rawContent === "") return;
        // 这里需要clone消息
        const msgCopy = cloneDeep(msg);

        // 检查是否所有填空项都已经被填满  
        const allBlanksFilled = blanks.every(blank => blank.selectedChoice);
        // 这里需要用blanks赋值！！！
        msgCopy.blanks = [...blanks];
        if (!allBlanksFilled) {
            // 给用户提示，请先完成所有填空项  
            message.error('请先完成所有填空');
            return;
        }
        // if (import.meta.env.PROD) {
        //     mixpanel.track('presetQuestionClick',
        //         { 'question': msgCopy.rawContent });
        // }
        msgCopy.rawContent = msgCopy.genTextToSend();
        handleSend(msgCopy, finishedPresetIndex!, currentQuestionIndex!)
        commonStatusStore.setIsClickPreset(true);
        if (setCurrentPresetQuestion) {
            setCurrentPresetQuestion(null);
        }
        setFinalMsgToSend(new ChatMessage({
            type: 3,
            responseMethod: "gpt",
            rawContent: "",
            userCanEditRawContent: true,
        })
        )
        setBlanks([]);
    }

    const sendBtnSrc = () => {
        if (hasBlank) {
            // 检查是否有填空，并且所有填空都已填完  
            return blanks.every(blank => blank.selectedChoice) ? SendAbleIcon : SendDisableIcon;
        } else {
            // 没有填空，根据 finalMsgToSend.rawContent 判断  
            return finalMsgToSend?.rawContent ? SendAbleIcon : SendDisableIcon;
        }
    };

    useEffect(() => {
        setBlanks(cloneDeep(msg?.blanks) || []);
    }, [(msg?.blanks)])

    //icon显示，播放时显示暂停，暂停时显示播放，生成音频时显示加载
    const showAudioIcon = () => {
        if (isPlayingMessage) {
            return isHoveredAudioPlayingIcon ? StopAudioIcon : AudioPlayingIcon;
        } else if (isGeneratingAudio) {
            return GenAudioLoading;
        } else {
            return PlayAudioIcon;
        }
    }

    const genVoice = async (text: string) => {
        let res: any
        try {
            setIsGeneratingAudio(true);
            res = await ttsAPI.genVoiceAndUpload(
                {
                    text: text,
                    voice_type: ttsVoice || 'BV700_streaming'
                }, 'tts');
            setAudioUrl(res.file_url);
            playQuestionAudio(res.file_url);
        } catch (error) {
            console.error('genVoiceAndUpload:', error);
            message.error('语音合成失败');
        } finally {
            setIsGeneratingAudio(false);
        }
    }

    const playQuestionAudio = (url: string) => {
        if (audioRef.current) {
            audioRef.current.src = url; // 设置为生成的音频源  
            audioRef.current.play().then(() => {
                audioStore.setCurrentAudioEleId(elementDataId);
                setIsPlayingMessage(true);
            }).catch((error) => {
                console.error('播放失败:', error);
            });
            audioRef.current.onended = () => {
                setIsPlayingMessage(false);
                audioStore.setCurrentAudioEleId(-1);
            }
        }
    };

    const pausePlaygroundAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlayingMessage(false);
        }
    };

    const handleAudioClick = async () => {
        if (isPlayingMessage) {
            // 如果正在播放，暂停  
            pausePlaygroundAudio();
        } else if (isGeneratingAudio) {
            // 如果正在生成，不做处理  
            return;
        } else {
            if (!finalMsgToSend) return;
            const msgCopy = cloneDeep(finalMsgToSend);
            // 检查是否所有填空项都已经被填满  
            const allBlanksFilled = blanks.every(blank => blank.selectedChoice);
            // 这里需要用blanks赋值！！！
            msgCopy.blanks = [...blanks];
            if (!allBlanksFilled) {
                // 给用户提示，请先完成所有填空项  
                message.error('请先完成所有填空');
                return;
            }
            msgCopy.rawContent = msgCopy.genTextToSend();
            // 如果没有生成音频，则进行生成  
            await genVoice(msgCopy.rawContent);
        }
    };

    useEffect(() => {
        isComponentMounted.current = true;
        return () => {
            isComponentMounted.current = false;
            if (audioRef.current) {
                audioRef.current.pause();
            }
        }
    }, [lectureStore.currentBlockIndex])

    useEffect(() => {
        if (audioStore.currentAudioEleId !== msg?.id && audioRef.current && isPlayingMessage) {
            audioRef.current.pause();
            setIsPlayingMessage(false);
            setIsGeneratingAudio(false);
        }
    }, [audioStore.currentAudioEleId])

    return (
        <>
            {!commonStatusStore.isFetching &&
                <div
                    className={`${styles.box} ${commonStatusStore.buttonFlashing ? styles.borderFlashing : ''}`}
                >
                    {
                        finalMsgToSend?.uploadFileUrl &&
                        <div className={styles.uploadFilePreviewBox}>
                            <div className={styles.uploadFilePreviewItem}>
                                <img
                                    className={styles.uploadFilePreviewItemImg}
                                    src={finalMsgToSend?.uploadFileUrl}
                                    alt="uploadFile"
                                />
                                <img
                                    src={CancelIcon}
                                    alt="cancel"
                                    className={styles.deleteIcon}
                                    onClick={() => {
                                        const newMsg = cloneDeep(finalMsgToSend);
                                        if (newMsg) {
                                            newMsg.uploadFileUrl = '';
                                        }
                                        setFinalMsgToSend(newMsg);
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {
                        !msg?.userCanEditRawContent && msg?.userCanEditRawContent !== undefined ?
                            <div
                                className={styles.presetQuestionItemBox} key={msg?.id}
                            >
                                {containsVariablePattern(msg?.rawContent)
                                    ? renderText(msg, rewriteText(msg?.rawContent))
                                    : renderText(msg, msg?.rawContent)}
                            </div> :
                            <textarea
                                className={` ${styles.autoInputArea}`}
                                placeholder="输入消息..."
                                value={finalMsgToSend?.rawContent}
                                onChange={(e) => {
                                    const newMsg = cloneDeep(finalMsgToSend);
                                    if (newMsg) {
                                        newMsg.rawContent = e.target.value;
                                    }
                                    setFinalMsgToSend(newMsg);
                                }
                                }
                                // onKeyDown={handleKeyDown}
                                onFocus={() => setIsInputFocused(true)}
                                onBlur={() => setIsInputFocused(false)}
                                ref={textareaRef}
                            />
                    }

                    <div className={styles.btnBox}>
                        {finalMsgToSend?.isUploadButtonVisible &&
                            <div className={styles.uploadFileBtn}>
                                <UploadFile
                                    icon={UploadFileIcon}
                                    // 允许上传图片和文本文档
                                    accept_str={uploadFileAcceptStr}
                                    isMultiple={false}
                                    uploadProps={{
                                        showUploadList: false,
                                    }}
                                    successCallBack={(url: string) => {
                                        if (!finalMsgToSend) return;
                                        const newMsg = cloneDeep(finalMsgToSend);
                                        newMsg.uploadFileUrl = url;
                                        setFinalMsgToSend(newMsg);
                                    }}
                                />
                            </div>}
                        <div className={styles.audioBtn}>
                            <img
                                src={showAudioIcon()}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleAudioClick();
                                }}
                                onMouseEnter={() => setIsHoveredAudioPlayingIcon(true)}
                                onMouseLeave={() => setIsHoveredAudioPlayingIcon(false)}
                            />
                        </div>
                        {!hasBlank && finalMsgToSend?.responseMethod !== 'preset' && finalMsgToSend?.userCanEditRawContent &&
                            <div className={styles.microphoneBtn}>
                                <SpeechToText
                                    setInputMessage={(message: string) => {
                                        const newMsg = cloneDeep(finalMsgToSend);
                                        if (newMsg) {
                                            newMsg.rawContent = message;
                                        }
                                        setFinalMsgToSend(newMsg);
                                    }
                                    }
                                    inputMessage={finalMsgToSend?.rawContent || ''}
                                />
                            </div>
                        }
                        <div className={`${styles.sendBtn} ${sendBtnSrc() === SendAbleIcon ? styles.sendBtnFlashing : ''}`}>
                            <img
                                //如果有填空项，且所有填空项都已经填满，则显示SendAbleIcon，否则显示SendDisableIcon，如果没有填空项，则在当前
                                src={sendBtnSrc()}
                                onClick={(e) => {
                                    e.preventDefault();
                                    sendPresetQuestion(finalMsgToSend!);
                                }}
                            />
                        </div>
                    </div>
                </div>
            }
            {choiceAreaVisible &&
                <div className={styles.choicesArea}>
                    <div className={styles.choicesBox}>
                        {blanks[selectedBlankIndex!]?.choicesStr && renderChoices(blanks[selectedBlankIndex!]?.choicesStr)}
                        {blanks[selectedBlankIndex!]?.allowCustomInput &&
                            <img
                                src={KeyboardIcon}
                                alt="keyboard"
                                className={styles.keyboardIcon}
                                onClick={clickKeyBoardIcon}
                            />
                        }
                        {
                            blanks[selectedBlankIndex!]?.allowCustomInput &&
                            <div className={styles.recordInputBox}>
                                <SpeechToText
                                    setInputMessage={(message: string) => {
                                        const newBlanks = [...blanks];
                                        newBlanks[selectedBlankIndex!].selectedChoice = message;
                                        msg.blanks = newBlanks;
                                        setBlanks(newBlanks);
                                    }}
                                    inputMessage={blanks[selectedBlankIndex!]?.selectedChoice || ''}
                                    microphoneIcon={BlankMicrophoneIcon}
                                />
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default observer(MessageInputAndSend);
