import { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import './editor.less'
import { cloneDeep, clone } from 'lodash';
import ElementModifier from './ElementModifier';
import RenderElement from '../Element/common/RenderElement'
import BlockEditorToolsPanel from './BlockEditorToolsPanel';
import BlockModifier from './components/BlockModifier/BlockModifier';
import {
    createElementContent,
    initElementFromTemplateAsync,
} from '../Element/common/ElementPropsFactory';
import { genObjectIDbyTS } from '@/utils/id_generator';
import CMSAPI from '@/api/cms';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import Toolbar from './Toolbar';
import { BlockData } from '@/base/BlockData';
import { ElementData, ElementContent } from '@/base/ElementData/ElementData';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import BtnGenericGroupRegionContent from '@/base/ElementData/BtnGenericGroupRegionContent';
import BtnTextGroupRegionContent from '@/base/ElementData/BtnTextGroupRegionContent';
import TextRegionContent from '@/base/ElementData/TextRegionContent';
import ImageRegionContent from '@/base/ElementData/ImageRegionContent';
import ButtonRegionContent from '@/base/ElementData/ButtonRegionContent';
import { genElementDatafromJSON } from '@/base/DataObjectGenerator';
import { ToolItem } from './BlockEditorToolsPanel';
import { ButtonWrapper } from '@/base/ElementData/UtilTypes';
import DataDefinition from '@/base/DataDefinition';

interface BlockEditorProps {
    block: BlockData;
    setBlock: (block: BlockData) => void;
    ref: React.RefObject<any>;
}

const BlockEditor = forwardRef<any, BlockEditorProps>(({
    block,
    setBlock,
}, ref) => {
    console.log("BlockEditor", block);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [itemToAdd, setItemToAdd] = useState<ToolItem | null>(null);// 选中的要添加的组件
    const [focusedItem, setFocusedItem] = useState<ElementData<ElementContent> | null>(null);// 当前选中要编辑的组件
    const [currentModifierItem, setCurrentModifierItem] = useState<ElementData<ElementContent> | null>(null);
    const [currentClipboardItem, setCurrentClipboardItem] = useState<ElementData<ElementContent> | null>(null); //存储当前复制的元素
    const [currentClipboardFormat, setCurrentClipboardFormat] = useState<ElementData<ElementContent> | null>(null); //存储当前复制的格式
    const [isFormatBrushActive, setIsFormatBrushActive] = useState(false);//是否开启格式刷模式
    const [imgUrl, setImgUrl] = useState('');// 图片地址
    const [videoUrl, setVideoUrl] = useState('');// 视频地址
    const [audioUrl, setAudioUrl] = useState('');// 音频地址
    const [templateProp, setTemplateProp] = useState<any>(null);
    const [currentOptionIndex, setCurrentOptionIndex] = useState<number>(-1);//当前修改的选择题的第几个选项

    interface ResourceProp {
        mode: number;
        src?: string;
        btnObj?: string;
    }

    const [resourceProp, setResourceProp] = useState<ResourceProp | null>(null);
    interface BtnTextGroupProp {
        mode: number;
        fullName?: string;
    }

    const [btnTextGroupProp, setBtnTextGroupProp] = useState<BtnTextGroupProp | null>(null);
    const { lectureStore } = useStores();
    //const [keyDown, setKeyDown] = useState(false);// 图片地址

    const setSrcUrlMap = {
        102: setImgUrl,
        103: setTemplateProp,
        104: setTemplateProp,
        105: setTemplateProp,
        106: setTemplateProp,
        107: setVideoUrl,
        108: setAudioUrl,
    };

    //清空选中的元素
    useImperativeHandle(ref, () => ({
        clearFocusedItem: () => {
            setFocusedItem(null);
            setCurrentModifierItem(null);
        }
    }));

    const handleSelectItem = (item: ToolItem) => {
        setItemToAdd(cloneDeep(item)); // 不直接引用原对象
        if (!item.needSetSrcUrl && !item.needSetTemplateUrl && !item.needSetResourceUrl) {
            document.body.style.cursor = 'copy';
        }
    };
    const handleClearItemToAdd = () => {
        setItemToAdd(null);
        document.body.style.cursor = 'default';
    };

    const addItem = async (defaultItemProps: any, newBlock: BlockData) => {
        console.log("defaultItemProps", defaultItemProps, "itemToAdd", itemToAdd);
        // 如果是图片或者视频，需要check Url
        if (itemToAdd?.key === 107 && !videoUrl) {
            return;
        }
        //if (itemToAdd.key === 108 && !audioUrl) {
        //    return;
        //}
        if (itemToAdd?.key === 104 && !templateProp) {
            return;
        }
        if (itemToAdd?.key === 106 && !templateProp) {
            return;
        }
        if (itemToAdd?.key === 103 && !templateProp) {
            return;
        }
        if (itemToAdd?.key === 105 && !templateProp) {
            return;
        }

        let itemProps: ElementData<ElementContent>;
        // 拿到那个最大的zIndex
        // find the largest zIndex in contentItems
        const [maxZIndex,] = newBlock.getMaxMinZIndex();
        if (templateProp && templateProp.fullName !== 'new') {
            if (itemToAdd?.key === 103 || itemToAdd?.key === 104 || itemToAdd?.key === 105 || itemToAdd?.key === 106) {
                const result = await initElementFromTemplateAsync(templateProp.fullName, defaultItemProps, maxZIndex!, genObjectIDbyTS());
                if (!result) {
                    throw new Error('Failed to initialize element from template');
                }
                itemProps = result;
            } else {
                throw new Error('templateProp is not null, but itemToAdd is not 104 or 103');
            }
        } else {
            console.log("resourceProp", resourceProp);
            console.log("templateProp", templateProp)
            let content: ElementContent;
            // 如果是104， 需要特殊处理
            if (itemToAdd?.key === 104) {
                content = new AdvancedButtonRegionContent();
                content.positionX = defaultItemProps.positionX;
                content.positionY = defaultItemProps.positionY;
                (content as AdvancedButtonRegionContent).initChildren(
                    templateProp.numTxtRegion,
                    templateProp.numImgRegion,
                    templateProp.numBtnRegion);
            } else if (itemToAdd?.key === 105) {
                content = new BtnTextGroupRegionContent();
                content.positionX = defaultItemProps.positionX;
                content.positionY = defaultItemProps.positionY;
            } else if (itemToAdd?.key === 106) {
                content = new BtnGenericGroupRegionContent();
                content.positionX = defaultItemProps.positionX;
                content.positionY = defaultItemProps.positionY;
            } else {
                content = createElementContent(itemToAdd?.key!, defaultItemProps);
            }

            itemProps = new ElementData<ElementContent>({
                id: genObjectIDbyTS(),
                type: itemToAdd?.key,
                content: content,
                isFocus: true
            });
            itemProps.content.zIndex = maxZIndex! + 1;

            // set topLevel
            //itemProps.topLevel = true;
        } //end
        newBlock.blockInfo.push(itemProps);
        console.log("contentItems", newBlock.blockInfo);

        setFocusedItem(newBlock.blockInfo[newBlock.blockInfo.length - 1]);
        setCurrentModifierItem(newBlock.blockInfo[newBlock.blockInfo.length - 1]);


        // 如果是图片或者视频，需要reset Url to null
        if (itemToAdd?.key === 102) {
            setImgUrl("");
        }
        if (itemToAdd?.key === 107) {
            setVideoUrl("");
        }
        if (itemToAdd?.key === 108) {
            setAudioUrl("");
        }
        if (itemToAdd?.key === 104 || itemToAdd?.key === 103 || itemToAdd?.key === 105 || itemToAdd?.key === 106) {
            setTemplateProp(null);
        }
        if (itemToAdd?.key === 106) {
            setResourceProp(null);
        }
        if (itemToAdd?.key === 105) {
            setBtnTextGroupProp(null);
        }
    };

    const changeZIndex = (id: number, direction: string) => {
        //需要处理children组件的Zindex
        const newBlock = clone(block);

        const targetItem = newBlock.getItemById(id);
        const [maxZIndex, maxId, minZIndex, minId] = newBlock.getMaxMinZIndex();

        if (direction === 'front') {
            // write a function to iterate thru newContentItems, return the max zIndex and the related id
            if (maxId === id) return;
            targetItem!.content.zIndex = maxZIndex! + 1;

        } else {
            if (minId === id) return;
            targetItem!.content.zIndex = minZIndex! - 1;
        }

        // 如果最小的zIndex是负数，全部加上一个正数，使得zIndex都是正数
        if (minZIndex! - 1 < 0) {
            newBlock.blockInfo.forEach((item) => {
                item.content.zIndex += (1 - minZIndex!);
                if (item.type === 104) {
                    (item.content as AdvancedButtonRegionContent).updateChildrenZIndex((1 - minZIndex!));
                }
            });
        }

        setBlock(newBlock);
    };

    const handleAddItem = (e: any) => {
        if (!itemToAdd) return; // 没有选中元素就直接返回

        console.log("handleAddItem", itemToAdd);

        const newBlock = clone(block);
        const flattenedItems = newBlock.getFlattenItems();
        //把flattenedItems中的isFocus全部设置为false
        flattenedItems.forEach((item) => {
            item.isFocus = false;
        });

        // 计算新的位置
        const rect = containerRef.current?.getBoundingClientRect();
        if (!rect) return;
        //四舍五入取整数
        const x = Math.round(e.clientX - rect.left);
        const y = Math.round(e.clientY - rect.top);
        const defaultItemProps = {
            positionX: x,
            positionY: y,
            imgUrl: imgUrl,
            videoUrl: videoUrl,
            audioUrl: audioUrl,
            templateUrl: templateProp?.fullName,
        }

        // 添加元素, 每个元素用默认值
        // addItem会进一步改变newBlock
        addItem(defaultItemProps, newBlock);
        lectureStore.setBlock(block);
        setBlock(newBlock);
        console.log("lectureStore.blockInfo", lectureStore.block!.blockInfo);


        e.stopPropagation(); // 阻止事件冒泡
        // 清除选中元素
        handleClearItemToAdd();
    };

    const handleFocusItem = (currentItem: ElementData<ElementContent>, e: any) => {
        if (itemToAdd) {
            return;
        }
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        const isShiftPressed = e.shiftKey;

        const newBlock = clone(block);
        const flattenItems = newBlock.getFlattenItems();

        // 如果没有按下 Shift 键，清除之前的选中状态
        if (!isShiftPressed) {
            flattenItems.forEach((item) => {
                item.isFocus = false;
            });
        }
        // 设置当前点击的项目为选中状态
        flattenItems.forEach((item) => {
            if (item.id === currentItem.id) {
                item.isFocus = true;
                if (item.type === 104 && item.childrenLevel) {
                    //找到它是哪个按钮组的子元素
                    const parentItem = newBlock.getParentItem(item);
                    if (parentItem) {
                        //设置当前是第几个选项
                        const index = (parentItem.content as BtnGenericGroupRegionContent).buttons.findIndex((button) => button.btnObj?.id === currentItem.id);
                        setCurrentOptionIndex(index);
                        parentItem.isFocus = true;
                    }
                }
            }
        });

        setBlock(newBlock);
        if (currentClipboardFormat) {
            handlePasteFormat(currentItem);
        }
        if (currentItem.type === 104 && currentItem.childrenLevel) return;
        setFocusedItem(currentItem);
        setCurrentModifierItem(newBlock.getParentItem(currentItem));
    };

    //在右边的操作栏中根据组件的类型可以修改组件不同的属性
    const onInputChange = (focusId: number, key: string, value: any) => {
        console.log("onInputChange", focusId, key, value);
        const newBlock = clone(block);//使用浅拷贝，
        const flattenItems = newBlock.getFlattenItems();


        flattenItems.forEach((item) => {
            if (item.id === focusId) {
                // TODO: btn的元数据需要重构下，这里是个暂时的dirty fix
                if (item.content) {
                    // item.content[key] = value;
                    const fld = key as keyof (typeof item.content);
                    item.content.setField(fld, value);
                    // setCurrentModifierItem(item);
                } //else {
                //item[key] = value;
                //}
            }
        });

        console.log("onInputChange -new block", newBlock);
        setBlock(newBlock);
    };

    useEffect(() => {
        const handleKeyDown = (e: any) => {
            const isMac = /Mac|iPad|iPhone/.test(navigator.userAgent); // 使用 userAgent 检测操作系统  
            const keyModifier = isMac ? e.metaKey : e.ctrlKey; // 在Mac上使用e.metaKey  

            // 检查事件目标，如果是输入框或文本区域，则不执行自定义的复制粘贴逻辑  
            const target = e.target as HTMLElement | null;
            if (target && (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA')) {
                return;
            }

            if (keyModifier) {
                if (e.key.toUpperCase() === 'C') {
                    handleCopyPaste(e, 'copy');
                } else if (e.key.toUpperCase() === 'V') {
                    handleCopyPaste(e, 'paste');
                }
            }
        };

        // 添加键盘事件监听器  
        document.addEventListener('keydown', handleKeyDown);

        // 清除事件监听器  
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [cloneDeep(block)]);

    //按下ctrl+c复制选中的元素,按下ctrl+v粘贴选中的元素,只针对文字和图片
    const handleCopyPaste = (e: any, mode: string) => {
        if (mode === 'copy' && currentModifierItem) {
            if (currentModifierItem.type === 101 || currentModifierItem.type === 102 || currentModifierItem.type === 104) {
                setCurrentClipboardItem(currentModifierItem);
            }
        } else if (mode === 'paste' && currentClipboardItem) {
            const newBlock = clone(block);
            const [maxZIndex] = newBlock.getMaxMinZIndex();

            // 创建新项目  
            const newItem: ElementData<ElementContent> = cloneDeep(currentClipboardItem);
            newItem.id = genObjectIDbyTS();
            newItem.content.positionX += 30;
            newItem.content.positionY += 30;
            newItem.content.zIndex = maxZIndex! + 1;
            newItem.isFocus = false;
            if (newItem.type === 104) {
                (newItem.content as AdvancedButtonRegionContent).resetChildrenId();
            }

            newBlock.blockInfo.push(newItem);
            setBlock(newBlock);
        }
    };

    //复制选中的元素的格式，只针对文字和图片
    const handleCopyFormat = () => {
        if (currentModifierItem) {
            if (currentModifierItem.type === 101 || currentModifierItem.type === 102) {
                setCurrentClipboardFormat(currentModifierItem);
                setIsFormatBrushActive(prev => !prev);
            }
        }
    };
    useEffect(() => {
        if (isFormatBrushActive) {
            document.body.style.cursor = 'url(/assets/img/format_painter_cur.png), auto';
        } else {
            document.body.style.cursor = 'default'; // Reset cursor  
        }
    }, [isFormatBrushActive]);

    //粘贴选中的元素的格式
    const handlePasteFormat = (currentModifierItem: ElementData<ElementContent>) => {
        // 目前只做了101和102的复制粘贴
        if (!currentClipboardFormat || !isFormatBrushActive) return;

        if (currentClipboardFormat.type === currentModifierItem.type) {
            if (currentModifierItem.type) {
                (currentModifierItem.content as TextRegionContent).text = (currentClipboardFormat.content as TextRegionContent).text;
            } else if (currentModifierItem.type === 102) {
                (currentModifierItem.content as ImageRegionContent).src = (currentClipboardFormat.content as ImageRegionContent).src;
                (currentModifierItem.content as ImageRegionContent).dynamicSrc = (currentClipboardFormat.content as ImageRegionContent).dynamicSrc;
            }
        }

        setCurrentClipboardFormat(null);
        setIsFormatBrushActive(false);
    };

    //选择题在右边的操作栏中根据选项的id修改选项的值
    const onOptionChange = async (focusId: number, key: string, btnId: number, value: any) => {
        console.log("onOptionChange", key, focusId, btnId, value);
        const newBlock = clone(block);
        const flattenItems = newBlock.getFlattenItems();
        let targetItem: ElementData<ElementContent> | null = null;
        let targetIndex = -1;
        const [maxZIndex, , , ,] = newBlock.getMaxMinZIndex();
        // TODO: this is a dirty fix, need to refactor
        let seeBtnGenricGrp: boolean = false;

        flattenItems.forEach((item) => {
            if (item.id === focusId) {
                // img
                if (item.type === 101 || item.type === 102) {
                    const fld = key as keyof (typeof item.content);
                    item.content.setField(fld, value);
                } else if (item.type === 106) { // generic button grp
                    let tIndex = 0;
                    (item.content as BtnGenericGroupRegionContent).buttons.forEach((btn) => {
                        if (btn.btnObj!.id === btnId) {
                            // 获取具体index, 修改操作涉及异步，在forEach外面做                         
                            targetItem = btn.btnObj!;
                            targetIndex = tIndex;
                            seeBtnGenricGrp = true;
                        }
                        tIndex++;
                    });
                } else if (item.type === 105) {
                    // txt btn grp
                    let tIndex = 0;
                    (item.content as BtnTextGroupRegionContent).buttons.forEach((btn) => {
                        if (btn.btnObj!.id === btnId) {
                            // 获取具体index, 修改操作涉及异步，在forEach外面做                            
                            targetItem = btn.btnObj!;
                            targetIndex = tIndex;
                        }
                        tIndex++;
                    });
                }
            }
        });

        if (seeBtnGenricGrp && targetIndex >= 0) {
            // 这个只是对于type 106
            let advBtnContent = targetItem!.content as AdvancedButtonRegionContent;
            if (value.mode && value.mode === 1) {
                advBtnContent.src = value.src;
                //if (advBtnContent.buttons[targetIndex].content) {
                //    advBtnContent.buttons[targetIndex] = null;
                //}
            } else if (value.mode && value.mode === 2) {
                advBtnContent.src = "";
                const btnJsonObj: any = await initElementFromTemplateAsync(value.btnObj,
                    { positionX: 10, positionY: 10 }, maxZIndex!, targetItem!.id);
                btnJsonObj.childrenLevel = true;
                advBtnContent = btnJsonObj.content;
            } else if (!value.mode) {
                // 这种是普通btn grp
                // 普通btn grp的每个btn obj没有content属性
                // TODO: 这里好像还没处理
                //targetItem[key] = value;
            }
            targetItem!.content = advBtnContent;
        }

        setBlock(newBlock);
    };


    //更改按钮组的选项
    const onAddOption = (focusId: number, btnNum: number) => {
        console.log("onAddOption", focusId, btnNum);
        const newBlock = clone(block);
        //这必须是一个按钮组
        // TODO: 这个function暂时disable了
        newBlock.blockInfo.map((item) => {
            if (item.id === focusId) {
                console.log("onAddOption find item", item);
                let btnGrpContent = (item.content as BtnGenericGroupRegionContent | BtnTextGroupRegionContent);
                if (btnNum > btnGrpContent.buttons.length) {
                    // 这里永远复制第一个, 这里需要更新所有不同level的id
                    for (let i = btnGrpContent.buttons.length; i < btnNum; i++) {
                        btnGrpContent.buttons[0].btnObj!.content.positionX = 0;
                        btnGrpContent.buttons[0].btnObj!.content.positionY = 0;
                        const newBtnObj = cloneDeep(btnGrpContent.buttons[0]);
                        // 需要改变id和position
                        newBtnObj.btnObj!.id = genObjectIDbyTS();
                        if (newBtnObj.btnObj!.type === 104) {
                            (newBtnObj.btnObj!.content as AdvancedButtonRegionContent).resetChildrenId();
                        }
                        btnGrpContent.buttons.push(newBtnObj);
                    }
                } else if (btnNum < btnGrpContent.buttons.length) {
                    btnGrpContent.buttons = btnGrpContent.buttons.slice(0, btnNum);
                }
            }
            // setCurrentModifierItem(item);
            return item;
        });
        console.log("onAddOption done", newBlock);
        setBlock(newBlock);
    }

    //拖拽结束后更新元素的位置
    const handleDragStop = (id: number, x: number, y: number) => {
        const newBlock = clone(block);
        const flattenItems = newBlock.getFlattenItems();

        flattenItems.forEach((item) => {
            if (item.id === id) {
                item.content.positionX = x;
                item.content.positionY = y;
                item.isFocus = true;
            }
        });

        setBlock(newBlock);
    };


    //拖拽时根据id替换这一个完整的item对象
    const handleResize = (newElement: ElementData<ElementContent>) => {
        const newBlock = clone(block);
        const itemToReplace = newBlock.getItemById(newElement.id);
        const [index, grpType, subIndex] = newBlock.getItemIndex(itemToReplace!);

        if (index >= 0 && !grpType) {
            newBlock.blockInfo[index] = newElement;

        } else if (index >= 0 && grpType && subIndex >= 0) {
            const advBtnContent = newBlock.blockInfo[index].content as AdvancedButtonRegionContent;
            if (grpType === 'text') {
                advBtnContent.children.textRegions[subIndex] = (newElement as ElementData<TextRegionContent>);
            } else if (grpType === 'img') {
                advBtnContent.children.imgRegions[subIndex] = (newElement as ElementData<ImageRegionContent>);
            } else if (grpType === 'btn') {
                advBtnContent.children.buttons[subIndex] = (newElement as ElementData<ButtonRegionContent>);
            }
        }
        setCurrentModifierItem(newBlock.getParentItem(newElement));
        setBlock(newBlock);
    };

    const handleDelete = (id: number) => {
        // 只删一级element, 暂时不用改这个function
        const newBlock = clone(block);
        const newContentItems = newBlock.blockInfo.filter(c => c.id !== id);
        newBlock.blockInfo = newContentItems;
        // 需要再做一个refocus的操作
        if (newBlock.blockInfo.length - 1 >= 0) {
            setFocusedItem(newBlock.blockInfo[newBlock.blockInfo.length - 1]);
            setCurrentModifierItem(newBlock.getParentItem(newBlock.blockInfo[newBlock.blockInfo.length - 1]));
        } else {
            setFocusedItem(null);
            setCurrentModifierItem(null);
        }

        setBlock(newBlock);
    }

    const handleContentChange = (currentItem: ElementData<ElementContent>, newText: string) => {
        const newBlock = clone(block);
        const [index, grpType, subIndex] = newBlock.getItemIndex(currentItem);

        if (index >= 0 && !grpType) {
            (currentItem.content as TextRegionContent).text = newText;
        } else if (index >= 0 && grpType && subIndex >= 0) {
            const advBtnContent = currentItem.content as AdvancedButtonRegionContent;
            if (grpType === 'text') {
                advBtnContent.children.textRegions[subIndex].content.text = newText;
            } else if (grpType === 'btn') {
                advBtnContent.children.buttons[subIndex].content.value = newText;
            }
        }

        if (index >= 0) {
            newBlock.blockInfo[index] = currentItem;
            setBlock(newBlock);
        }
    };

    const onSaveAsTemplate = (itemId: number, url: string) => {
        //const itemCopy = structuredClone(currentItem);
        const item = block.getItemById(itemId);
        // 需要抽取出相关部分，存为模板
        let fileName: string | undefined = url.split('/').pop();
        // 去掉文件名中的 .jpg 扩展名
        fileName = fileName?.replace('.', '-');
        const templateObj: any = {};
        // 后端会自动生成id，这里给空值就可以
        templateObj.id = '';
        templateObj.type = item!.type;
        templateObj.name = fileName;
        templateObj.thumbnail = url;
        templateObj.template = item?.content.toJSON();

        console.log("onSaveAsTemplate -- fin templateObj", templateObj);
        const api = new CMSAPI();
        api.storeTemplate(templateObj).then((res) => {
            console.log("onSaveAsTemplate -- res", res);
        }).catch((error) => {
            console.error('onSaveAsTemplate:', error);
        });
    };



    return (<div className="editor">
        <div className="editor-top">
            <BlockEditorToolsPanel
                selectedItem={itemToAdd!}
                handleSelectItem={handleSelectItem}
                setSrcUrlMap={setSrcUrlMap} />
        </div>
        <div className='editor-body'>
            <div className='editor-toolbar'>
                <Toolbar
                    handleCopyFormat={handleCopyFormat}
                />
            </div>
            <div className="editor-container" style={{
                backgroundImage: `url(${block?.extras.background || lectureStore.blockBackground || ''})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
                {/* 负责产生滚动条 */}
                <div className="editor-container-canvas">
                    {/* 产生内容区域 */}
                    <div
                        className="editor-container-canvas_content"
                        style={{ width: "1024px", height: '768px', overflow: 'hidden' }}
                        ref={containerRef}
                        onClick={handleAddItem}
                    >
                        {block?.blockInfo.map((item, index) =>
                            <RenderElement
                                key={item.id}
                                elementData={item}
                                isEditable={true}
                                handleFocusItem={handleFocusItem}
                                handleResize={handleResize}
                                handleDragStop={handleDragStop}
                                handleDelete={handleDelete}
                                handleContentChange={handleContentChange}
                                index={index}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className='editor-operation'>
                <BlockModifier
                    block={block}
                    setBlock={setBlock}
                />
                <hr />
                <ElementModifier
                    focusedItem={currentModifierItem!}
                    onInputChange={onInputChange}
                    onOptionChange={onOptionChange}
                    onAddOption={onAddOption}
                    changeZIndex={changeZIndex}
                    onSaveAsTemplate={onSaveAsTemplate}
                    containerRef={containerRef}
                    currentOptionIndex={currentOptionIndex}
                />
            </div>
        </div>
    </div>)
});

BlockEditor.displayName = 'BlockEditor';
export default observer(BlockEditor);

