import { ElementContent } from './ElementData';

class RollingBallRegionContent extends ElementContent {

    constructor({
        positionX = 0,
        positionY = 0,
        width = 950,
        height = 500,
    }: Partial<RollingBallRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height','zIndex'];
    }
};

export default RollingBallRegionContent;