import React, { useEffect, useState, useRef } from 'react';
import styles from "./trainingLogRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import TrainingLogRegionContent from '@/base/ElementData/TrainingLogRegionContent';
import {
    Chart as ChartJS, // Chart.js 主对象  
    CategoryScale,    // 类别刻度 (X轴)  
    LinearScale,      // 线性刻度 (Y轴)  
    LineElement,      // 线元素  
    PointElement,     // 点元素  
    LineController,   // 线控制器  
} from 'chart.js';
// 注册所需的组件  
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, LineController);

const TrainingLogRegion: React.FC<IntrinsicElementProps<TrainingLogRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
}) => {
    const [epoch, setEpoch] = useState(1);
    const [logMessages, setLogMessages] = useState<string[]>([]);
    const [isTraining, setIsTraining] = useState(false);
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<ChartJS | null>(null);
    const intervalId = useRef<number | null>(null);
    const lossData = useRef<number[]>([]);

    useEffect(() => {
        if (chartRef.current) {
            chartInstance.current = new ChartJS(chartRef.current, {
                type: 'line',
                data: {
                    labels: Array.from({ length: 10 }, (_, i) => i + 1),
                    datasets: [{
                        label: 'Loss',
                        data: [],
                        borderColor: 'rgb(75, 192, 192)',
                        fill: false,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            title: { display: true, text: 'Epoch' },
                            min: 0,
                            max: 10,
                            ticks: {
                                stepSize: 1,
                            }
                        },
                        y: {
                            title: { display: true, text: 'Loss' },
                            min: 0,
                            max: 0.7,
                        }
                    }
                }
            });
        }

        return () => {
            chartInstance.current?.destroy();
            if (intervalId.current) clearInterval(intervalId.current);
        };
    }, []);

    const startTraining = () => {
        setIsTraining(true);
        setLogMessages([]);
        lossData.current = [];
        setEpoch(1);
        let currentEpoch = 1;
        let currentLoss = 0.55 + Math.random() * 0.05;

        intervalId.current = setInterval(() => {
            if (currentEpoch <= 10) {
                if (currentEpoch === 2) {
                    currentLoss = currentLoss - 0.24 - Math.random() * 0.05;
                } else if (currentEpoch === 3) {
                    currentLoss = currentLoss - 0.055 - Math.random() * 0.015;
                } else if (currentEpoch <= 5) {
                    currentLoss = currentLoss - 0.02 - Math.random() * 0.004;
                } else {
                    currentLoss = currentLoss - 0.008 - Math.random() * 0.002;
                }

                const formattedLoss = Number(currentLoss.toFixed(6));
                lossData.current.push(formattedLoss);

                setLogMessages(prev => [...prev, `Epoch ${currentEpoch - 1}/10: Loss: ${formattedLoss}\n`]);

                if (chartInstance.current) {
                    chartInstance.current.data.datasets[0].data = [...lossData.current];
                    chartInstance.current.update();
                }

                currentEpoch++;
                setEpoch(currentEpoch);
            } else {
                if (intervalId.current) clearInterval(intervalId.current);
                const accuracy = 100 - currentLoss * 30 - Math.random() * 1.1;
                setLogMessages(prev => [...prev, `Accuracy: ${accuracy.toFixed(3)}%\n`]);
                setIsTraining(false);
            }
        }, 1000);
    };

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.trainingLogBox}>
                    <div className={styles.mainBox}>
                        <div className={styles.logBox}>
                            {logMessages.join('')}
                        </div>
                        <div className={styles.chartContainer}>
                            <canvas ref={chartRef} />
                        </div>
                    </div>

                    <button
                        className={styles.trainBtn}
                        onClick={startTraining}
                        disabled={isTraining}
                    >
                        训练
                    </button>
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default TrainingLogRegion;