import { ElementContent } from './ElementData';

class NNVisRegionContent extends ElementContent {

    constructor({
        positionX = 0,
        positionY = 0,
        width = 700,
        height = 530,
    }: Partial<NNVisRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex'];
    }
};

export default NNVisRegionContent;