import React, { useEffect, useState } from 'react';
import styles from "./treasureHuntRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import TreasureHuntRegionContent from "@/base/ElementData/TreasureHuntRegionContent";

const TreasureHuntRegion: React.FC<IntrinsicElementProps<TreasureHuntRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
}) => {
    const { gameType } = elementData.content;

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.treasureHuntBox}>
                    {gameType === 'treasureHunt' ? <TreasureHuntGame /> : <TreasureHuntGameWithTip />}
                </div>
            </div>
        </BaseDragableElement>
    );
};

const TreasureHuntGame = () => {
    const [keyPosition, setKeyPosition] = useState({ x: Math.floor(Math.random() * 8), y: Math.floor(Math.random() * 8) });
    const [guessCount, setGuessCount] = useState(0);
    const [tiles, setTiles] = useState(Array(64).fill(null)); // 64个格子  
    const [isGameOver, setIsGameOver] = useState(false);

    const checkTile = (x: number, y: number, index: number) => {
        if (isGameOver || tiles[index]) return; // 如果游戏结束或该格子已被点击，则不再进行操作  

        setGuessCount(count => count + 1);
        const newTiles = [...tiles];

        if (x === keyPosition.x && y === keyPosition.y) {
            newTiles[index] = <div style={{ backgroundImage: `url(/assets/img/treasure.svg)`, backgroundSize: 'cover', width: '100%', height: '100%' }} />;
            setIsGameOver(true);
        } else {
            newTiles[index] = <div style={{ backgroundColor: '#22375E', width: '100%', height: '100%', borderRadius: '.8rem' }} />; // 错误点击后变色  
        }
        setTiles(newTiles);
    };

    const playAgain = () => {
        setKeyPosition({ x: Math.floor(Math.random() * 8), y: Math.floor(Math.random() * 8) });
        setGuessCount(0);
        setTiles(Array(64).fill(null));
        setIsGameOver(false);
    }

    return (
        <div className={styles.treasureHuntGame}>
            <div className={styles.gridBox}>
                {tiles.map((tile, index) => (
                    <div
                        className={styles.tile}
                        key={index}
                        onClick={() => checkTile(index % 8, Math.floor(index / 8), index)}
                        style={{
                            backgroundColor: tile ? '#22375E' : '#BCCDEF',  // 未点击的格子为 #BCCDEF，已点击的为 #22375E  
                            cursor: isGameOver || tile ? 'not-allowed' : 'pointer' // 如果游戏结束或格子已点击，禁用鼠标点击样式  
                        }}>
                        {tile}
                    </div>
                ))}
            </div>
            <button onClick={playAgain} className={styles.playAgainBtn}>再试一次</button>
        </div>
    );
};

const TreasureHuntGameWithTip = () => {
    const colors = ['#FF3239', '#FF279A', '#7D32FF', '#FF8534', '#1F8DFA'];
    const [keyPosition, setKeyPosition] = useState({ x: Math.floor(Math.random() * 8), y: Math.floor(Math.random() * 8) });
    const [guessCount, setGuessCount] = useState(0);
    const [tiles, setTiles] = useState(Array(64).fill(null)); // 64个格子  
    const [isGameOver, setIsGameOver] = useState(false);

    const checkTile = (x: number, y: number, index: number) => {
        if (isGameOver) return;

        const distance = Math.sqrt(Math.pow(keyPosition.x - x, 2) + Math.pow(keyPosition.y - y, 2));
        const roundedDistance = Math.round(distance);
        let tileValue;

        if (roundedDistance === 0) {
            tileValue = <div style={{ backgroundImage: `url(/assets/img/treasure.svg)`, backgroundSize: 'cover', width: '100%', height: '100%' }} />;
            setIsGameOver(true);
        } else {
            tileValue = roundedDistance;
            let colorIndex;
            if (roundedDistance <= 1) {
                colorIndex = 0;
            } else if (roundedDistance <= 2) {
                colorIndex = 1;
            } else if (roundedDistance <= 3) {
                colorIndex = 2;
            } else if (roundedDistance <= 4) {
                colorIndex = 3;
            } else {
                colorIndex = 4;
            }
            tileValue = <div
                style={{
                    backgroundColor: colors[colorIndex],
                    width: "100%", height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: ".8rem",
                    color: "#fff",
                }}>
                {roundedDistance}
            </div>;
        }

        setTiles(prevTiles => {
            const newTiles = [...prevTiles];
            newTiles[index] = tileValue;
            return newTiles;
        });
        setGuessCount(count => count + 1);
    };

    const playAgain = () => {
        setKeyPosition({ x: Math.floor(Math.random() * 8), y: Math.floor(Math.random() * 8) });
        setGuessCount(0);
        setTiles(Array(64).fill(null));
        setIsGameOver(false);
    }

    return (
        <div className={styles.treasureHuntGame}>
            <div className={styles.gridBox}>
                {tiles.map((tile, index) => (
                    <div
                        className={styles.tile}
                        key={index}
                        onClick={() => checkTile(index % 8, Math.floor(index / 8), index)}
                        style={{
                            backgroundColor: tile ? '#22375E' : '#BCCDEF',
                            cursor: isGameOver || tile ? 'not-allowed' : 'pointer'
                        }}
                    >
                        {tile}
                    </div>
                ))}
            </div>
            <button onClick={playAgain} className={styles.playAgainBtn}>再试一次</button>
        </div>
    );
};

export default TreasureHuntRegion;