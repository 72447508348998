import PropTypes from 'prop-types';
import { InputNumber, Button, Select, Radio, Input, Switch } from 'antd';
import styles from '../../elementModifier.module.less';
import useCommonFunction from '@/hooks/useCommonFunction';
import { useState, useEffect } from 'react';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import MediaFileSelector from '@/components/MediaFileSelector/MediaFileSelector';
import MultimediaAPI from '@/api/multimedia';
import { ElementData } from '@/base/ElementData/ElementData';
import ButtonRegionContent from '@/base/ElementData/ButtonRegionContent';

interface ButtonRegionModifierProps {
    focusedItem: ElementData<ButtonRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, type: string) => void;
    onSaveAsTemplate?: (itemId: number, url: string) => void;
    containerRef?: React.RefObject<HTMLDivElement>;
    useAsChild?: boolean;
}

const ButtonRegionModifier: React.FC<ButtonRegionModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex,
    onSaveAsTemplate,
    containerRef,
    useAsChild = false,
}) => {
    const ttsAPI = new MultimediaAPI();
    const { generateSelectOptionsData, generateElementIdOptions,
        generateDownloadOptions, generateShareTypeOptions } = useCommonFunction();
    const optionsData = generateSelectOptionsData();
    const elementIdOptions = generateElementIdOptions();
    const downloadTypeParam = generateDownloadOptions();
    const shareTypeParam = generateShareTypeOptions();
    const [grandparentSize, setGrandparentSize] = useState({ width: 0, height: 0 });
    const [fileSelectorOpen, setFileSelectorOpen] = useState(false);
    const { Option } = Select;
    const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
    const [isGeneratingNewWordAudio, setIsGeneratingNewWordAudio] = useState(false);

    // 生成音频
    const generateAudio = async () => {
        setIsGeneratingAudio(true);
        try {
            const res = await ttsAPI.genVoiceAndUpload({ text: focusedItem.content.value }, 'tts');
            onInputChange(focusedItem.id, 'valueAudioUrl', res.file_url);
        } catch (error) {
            console.error('Error generating audio:', error);
        } finally {
            setIsGeneratingAudio(false);
        }
    }
    // 生成新的教师说的音频
    const generateNewWordAudio = async (newWords: string) => {
        setIsGeneratingNewWordAudio(true);
        try {
            // 音色用 BV700_streaming
            const res = await ttsAPI.genVoiceAndUpload({ text: newWords, voice_type: 'BV700_streaming' }, 'tts');
            changeParams('audioUrl', res.file_url);
        } catch (error) {
            console.error('Error generating audio:', error);
        } finally {
            setIsGeneratingNewWordAudio(false);
        }
    }

    //定义垂直居中按钮的点击事件
    const handleVerticalCenter = () => {
        let centerTop = (grandparentSize.height - focusedItem.content.height) / 2;
        onInputChange(focusedItem.id, 'positionY', centerTop);
    }
    //水平居中，
    const handleHorizontalCenter = () => {
        let centerLeft = (grandparentSize.width - focusedItem.content.width) / 2;
        onInputChange(focusedItem.id, 'positionX', centerLeft);
    }

    const successCallBack = (url: string, itemId: number) => {
        onSaveAsTemplate!(itemId, url);

    };

    //focusedItem.content.onclick_func的结构是{funcName: 'xxx', funcParams: {xxx: xxx}}
    //修改focusedItem.content.onclick_func的funcName
    const changeFuncName = (value: string) => {
        const newFunc = { ...focusedItem.content.onclickFunc, funcName: value };
        onInputChange(focusedItem.id, 'onclickFunc', newFunc);
    }

    const changeParams = (paramName: string, value: any) => {
        const newParams = { ...focusedItem.content.onclickFunc?.funcParams, [paramName]: value };
        const newFunc = { ...focusedItem.content.onclickFunc, funcParams: newParams };
        onInputChange(focusedItem.id, 'onclickFunc', newFunc);
    }

    const propsUpload = {
        itemId: focusedItem.id,
    };
    useEffect(() => {
        // 确保ref被挂载
        if (containerRef?.current) {
            const element = containerRef.current.querySelector('[data-findparent="findparent"]');
            if (element) {
                const grandparent = element.parentElement?.parentElement; // 获取祖父元素
                // 确保祖父元素存在
                if (grandparent) {
                    setGrandparentSize({
                        width: grandparent.offsetWidth,
                        height: grandparent.offsetHeight,
                    });
                }
            }
        }
    }, [containerRef]); // 依赖项中包含ref，以确保在挂载后执行
    return (
        <div>
            {!useAsChild &&
                <>
                    <UploadMedia
                        successCallBack={successCallBack}
                        icon={null}
                        btn_text="存为模板 (先上传Thumbnail)"
                        accept_str="image/png,image/jpg,image/jpeg,image/gif,image/svg"
                        dir="images"
                        isMultiple={false}
                        uploadProps={propsUpload}
                    />
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>X</div>
                        <InputNumber
                            value={focusedItem.content.positionX}
                            onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                        />
                        <div className={styles.modifyItemTitle}>Y</div>
                        <InputNumber
                            value={focusedItem.content.positionY}
                            onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                        />
                    </div>
                </>
            }
            <div className={styles.divVerticalLayout}>
                <label>按钮内容</label>
                <textarea
                    value={focusedItem.content.value!}
                    onChange={e => onInputChange(focusedItem.id, 'value', e.target.value)}
                    className={styles.textarea_small}
                />
            </div>
            <div>
                <label>宽度</label>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
            </div>
            <div>
                <label>高度</label>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div>
                <label>绑定函数</label>
                <Select
                    value={focusedItem.content.onclickFunc?.funcName}
                    onChange={value => changeFuncName(value)}
                    className={styles.functionSelect}
                >
                    {optionsData.map(option => (
                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
            </div>
            {(focusedItem.content.onclickFunc?.funcName === 'showSpecifiedElement' ||
                focusedItem.content.onclickFunc?.funcName === 'showSpecifiedElementAndHide' ||
                focusedItem.content.onclickFunc?.funcName === 'changeTeacherWords' ||
                focusedItem.content.onclickFunc?.funcName === 'changeBtnTextAndStyleAndTeacherWords') && (
                    <div>
                        <label>参数:elementId</label>
                        <Select
                            value={focusedItem.content.onclickFunc?.funcParams?.elementId}
                            onChange={value => changeParams('elementId', value)}
                            className={styles.functionSelect}
                        >
                            {elementIdOptions.map(option => (
                                <Option key={option.value} value={option.value}>{option.label}</Option>
                            ))}
                        </Select>
                    </div>
                )}
            {
                (focusedItem.content.onclickFunc?.funcName === 'changeTeacherWords' ||
                    focusedItem.content.onclickFunc?.funcName === 'changeBtnTextAndStyleAndTeacherWords') && (
                    <>
                        <div className={styles.divVerticalLayout}>
                            <label>参数:new teacher words</label>
                            <textarea
                                value={focusedItem.content.onclickFunc?.funcParams?.newWords}
                                onChange={e => changeParams('newWords', e.target.value)}
                                className={styles.textarea_small}
                            />
                        </div>
                        <div className={styles.divVerticalLayout}>
                            <label>参数:audio url</label>
                            <Button
                                onClick={() => generateNewWordAudio(focusedItem.content?.onclickFunc?.funcParams?.newWords)}
                                loading={isGeneratingNewWordAudio}
                            >
                                生成音频
                            </Button>
                        </div>
                        <div className={styles.divVerticalLayout}>
                            <label>当前参数音频的链接:</label>
                            {focusedItem.content?.onclickFunc?.funcParams?.audioUrl ? focusedItem.content?.onclickFunc?.funcParams?.audioUrl : "暂无"}
                        </div>
                    </>
                )
            }
            {focusedItem.content.onclickFunc?.funcName === 'navigate' && (
                <div >
                    <label>url</label>
                    <textarea
                        value={focusedItem.content.onclickFunc?.funcParams?.url}
                        onChange={e => changeParams('url', e.target.value)}
                        className={styles.textareaVar} />
                </div>
            )}
            {focusedItem.content.onclickFunc?.funcName === 'callAPI' && (
                <div className={styles.divVerticalLayout}>
                    <label>api url</label>
                    <textarea
                        value={focusedItem.content.onclickFunc?.funcParams?.url}
                        onChange={e => changeParams('url', e.target.value)}
                        className={styles.textareaVar} />
                    <label>data json</label>
                    <textarea
                        value={focusedItem.content.onclickFunc?.funcParams?.data}
                        onChange={e => changeParams('data', e.target.value)}
                        className={styles.textareaVar} />
                    <label>method</label>
                    <Select
                        value={focusedItem.content.onclickFunc?.funcParams?.method}
                        onChange={value => changeParams('method', value)}
                    >
                        <Select.Option value='post'>Post</Select.Option>
                        <Select.Option value='put'>Put</Select.Option>
                    </Select>
                </div>
            )}
            {
                focusedItem.content.onclickFunc?.funcName === 'downloadFile' && (
                    <div>
                        <label>参数：下载类型</label>
                        <Select
                            value={focusedItem.content.onclickFunc?.funcParams?.downloadType}
                            onChange={value => changeParams('downloadType', value)}
                        >
                            {downloadTypeParam.map(option => (
                                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                            ))}
                        </Select>
                    </div>
                )
            }
            {
                focusedItem.content.onclickFunc?.funcName === 'share' && (
                    <div>
                        <label>参数：分享类型</label>
                        <Select
                            value={focusedItem.content.onclickFunc?.funcParams?.shareType}
                            onChange={value => changeParams('shareType', value)}
                        >
                            {shareTypeParam.map(option => (
                                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                            ))}
                        </Select>
                    </div>
                )
            }
            {
                focusedItem.content.onclickFunc?.funcName === 'changeBtnTextAndStyleAndTeacherWords' && (
                    <div>
                        <label>参数：BtnText</label>
                        <textarea
                            value={focusedItem.content.onclickFunc?.funcParams?.text}
                            onChange={e => changeParams('text', e.target.value)}
                            className={styles.textarea_small}
                        />
                        <label>参数：Text Color</label>
                        <input
                            type="color"
                            value={focusedItem.content.onclickFunc?.funcParams?.textColor}
                            onChange={e => changeParams('textColor', e.target.value)}
                            className={styles.colorInput}
                        />
                    </div>
                )
            }
            <div>
                <label>字体颜色</label>
                <input
                    type="color"
                    value={focusedItem.content.fontColor}
                    onChange={e => onInputChange(focusedItem.id, 'fontColor', e.target.value)}
                    className={styles.colorInput}
                />
            </div>
            <div>
                <label>背景颜色</label>
                <input
                    type="color"
                    value={focusedItem.content.backgroundColor}
                    onChange={e => onInputChange(focusedItem.id, 'backgroundColor', e.target.value)}
                    className={styles.colorInput}
                />
            </div>
            <div>
                <label>圆角半径</label>
                <InputNumber
                    value={focusedItem.content.borderRadius}
                    onChange={value => onInputChange(focusedItem.id, 'borderRadius', value)}
                    className={styles.borderRadiusInput}
                />
            </div>
            <div>
                <label>字体大小</label>
                <InputNumber
                    value={focusedItem.content.fontSize}
                    onChange={value => onInputChange(focusedItem.id, 'fontSize', value)}
                    className={styles.fontSizeInput}
                />
            </div>
            <div>
                <label>边框宽度</label>
                <InputNumber
                    value={focusedItem.content.borderWidth}
                    onChange={value => onInputChange(focusedItem.id, 'borderWidth', value)}
                    className={styles.borderWidthInput}
                />
            </div>
            <div>
                <label>边框颜色</label>
                <input
                    type="color"
                    value={focusedItem.content.borderColor}
                    onChange={e => onInputChange(focusedItem.id, 'borderColor', e.target.value)}
                    className={styles.colorInput}
                />
            </div>
            {/* 添加阴影设置 */}
            <div>
                <label>阴影水平偏移(px)</label>
                <InputNumber
                    value={focusedItem.content.shadowX}
                    onChange={value => onInputChange(focusedItem.id, 'shadowX', value)}
                    className={styles.shadowInput}
                />
            </div>
            <div>
                <label>阴影垂直偏移(px)</label>
                <InputNumber
                    value={focusedItem.content.shadowY}
                    onChange={value => onInputChange(focusedItem.id, 'shadowY', value)}
                    className={styles.shadowInput}
                />
            </div>
            <div>
                <label>阴影模糊半径(px)</label>
                <InputNumber
                    value={focusedItem.content.shadowBlur}
                    onChange={value => onInputChange(focusedItem.id, 'shadowBlur', value)}
                    className={styles.shadowInput}
                />
            </div>
            <div>
                <label>阴影扩散半径(px)</label>
                <InputNumber
                    value={focusedItem.content.shadowSpread}
                    onChange={value => onInputChange(focusedItem.id, 'shadowSpread', value)}
                    className={styles.shadowInput}
                />
            </div>
            <div>
                <label>阴影颜色</label>
                <input
                    type="color"
                    value={focusedItem.content.shadowColor}
                    onChange={e => onInputChange(focusedItem.id, 'shadowColor', e.target.value)}
                    className={styles.colorInput}
                />
            </div>
            <div>
                <label>开启Bullet设置</label>
                <Radio.Group onChange={e => onInputChange(focusedItem.id, 'bulletEnabled', e.target.value)}
                    value={focusedItem.content.bulletEnabled}>
                    <Radio value={true}>Enabled</Radio>
                    <Radio value={false}>Disabled</Radio>
                </Radio.Group>
            </div>
            <div>
                <label>按钮circle半径(px)</label>
                <InputNumber
                    value={focusedItem.content.bulletCircleRadius}
                    onChange={value => onInputChange(focusedItem.id, 'bulletCircleRadius', value)}
                    className={styles.shadowInput}
                    disabled={!focusedItem.content.bulletEnabled}
                />
            </div>
            <div>
                <label>按钮circle颜色</label>
                <input
                    type="color"
                    value={focusedItem.content.bulletCircleColor}
                    onChange={e => onInputChange(focusedItem.id, 'bulletCircleColor', e.target.value)}
                    className={styles.colorInput}
                    disabled={!focusedItem.content.bulletEnabled}
                />
            </div>
            <div>
                <label>按钮bullet文字颜色</label>
                <input
                    type="color"
                    value={focusedItem.content.bulletLetterColor}
                    onChange={e => onInputChange(focusedItem.id, 'bulletLetterColor', e.target.value)}
                    className={styles.colorInput}
                    disabled={!focusedItem.content.bulletEnabled}
                />
            </div>
            <div>
                <label>按钮bullet文字大小</label>
                <InputNumber
                    value={focusedItem.content.bulletLetterSize}
                    onChange={value => onInputChange(focusedItem.id, 'bulletLetterSize', value)}
                    className={styles.shadowInput}
                    disabled={!focusedItem.content.bulletEnabled}
                />
            </div>
            <div>
                <label>按钮bullet文字</label>
                <textarea
                    value={focusedItem.content.bulletLetter}
                    onChange={e => onInputChange(focusedItem.id, 'bulletLetter', e.target.value)}
                    className={styles.textareaVar}
                    disabled={!focusedItem.content.bulletEnabled} />
            </div>
            <div>
                <label>按钮点击音效</label>
                <Button type='primary' onClick={() => setFileSelectorOpen(true)}>选择音效</Button>
                <MediaFileSelector
                    mediaType="audios"
                    afterSelectionCallback={url => onInputChange(focusedItem.id, 'click_sound_url', url)}
                    fileSelectorOpen={fileSelectorOpen}
                    setFileSelectorOpen={setFileSelectorOpen}
                />
            </div>
            <div>
                <label>当前是否有点击音效: </label>
                {focusedItem.content.clickSoundUrl ? '有' : '无'}
            </div>
            <div>
                <label>是否展示播放按钮内容图标</label>
                <Switch
                    checked={focusedItem.content.showAudioIcon}
                    onChange={value => onInputChange(focusedItem.id, 'showAudioIcon', value)}
                />
            </div>
            <div>
                <label>点击生成按钮内容语音链接</label>
                <Button
                    type="primary"
                    loading={isGeneratingAudio}
                    onClick={generateAudio}
                >
                    生成语音
                </Button>
            </div>
            <div>
                <label>当前是否已有按钮内容音频链接：</label>
                {focusedItem.content.valueAudioUrl ? focusedItem.content.valueAudioUrl : '暂无'}
            </div>
            <div>
                <Button onClick={handleVerticalCenter}>垂直居中</Button>
                <Button onClick={handleHorizontalCenter}>水平居中</Button>
            </div>
            <div>
                <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
            </div>
        </div>
    )
};

export default ButtonRegionModifier;