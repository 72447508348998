import DataDefinition from "../DataDefinition";

class BasicShareWithSoundInfo extends DataDefinition {
    title: string;
    content: string;
    imgUrl: string;
    contentVoiceUrl: string;
    shareDescription: string;
    contentBackgroundColor: string;
    contentTextAlign: string;

    constructor({
        title = "",
        content = "",
        imgUrl = "",
        contentVoiceUrl = "",
        shareDescription = "",
        contentBackgroundColor = "",
        contentTextAlign = ""
    }: Partial<BasicShareWithSoundInfo> = {}) {
        super();
        this.allowedFields = ['title', 'content', 'imgUrl', 'backgroundStyle', 'authorName',
            'contentVoiceUrl', 'shareDescription', 'contentBackgroundColor', 'contentTextAlign'];
        this.title = title;
        this.content = content;
        this.imgUrl = imgUrl;
        this.contentVoiceUrl = contentVoiceUrl;
        this.shareDescription = shareDescription;
        this.contentBackgroundColor = contentBackgroundColor;
        this.contentTextAlign = contentTextAlign;
    }
}

export default BasicShareWithSoundInfo;