import { useCallback } from 'react';
import { useStores } from '@/store/useStores';
import { useNavigate } from 'react-router-dom';
import service from '@/services/axios';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import { userShareObject } from '@/api/userLikeObject';
import { createUserWork } from '@/api/userWork';
import { generateMD5 } from '@/utils/utils';
import { message } from 'antd';

type ElementIdOption = { value: any, label: any };

const useCommonFunction = () => {
    const { commonStatusStore, lectureStore, userInfoStore } = useStores();
    const navigateHelper = useNavigate();
    // 定义可调用的函数
    const openTeacherDialog = () => {
        commonStatusStore.openDialog();
    };

    const startLearn = () => {
        lectureStore.continueFunc();
    };

    const showSpecifiedElement = (params: { elementId: number }) => {
        //在elementRefs中找到对应的ref，然后调用showElement方法
        console.log('showSpecifiedElement -- elementRefs', lectureStore.elementRefs, params);
        const elementRef = lectureStore.elementRefs.find(eleRef => eleRef.id === params.elementId);
        if (elementRef) {
            elementRef.ref.current!.showElement();
        }
    };

    // 函数body和showSpecifiedElement一样，主要是用函数名
    const showSpecifiedElementAndHide = (params: { elementId: number }) => {
        //在elementRefs中找到对应的ref，然后调用showElement方法
        console.log('showSpecifiedElementAndHide -- elementRefs', lectureStore.elementRefs);
        const elementRef = lectureStore.elementRefs.find(eleRef => eleRef.id === params.elementId);
        console.log('showSpecifiedElementAndHide -- elementRef', elementRef);
        if (elementRef) {
            elementRef.ref.current.showElement();
        }
    };

    const navigate = (params: { url: string }) => {
        navigateHelper(params.url);
    };

    const changeTeacherWords = (params: { elementId: number, newWords: string, audioUrl: string }) => {
        const elementRef = lectureStore.elementRefs.find(eleRef => eleRef.id === params.elementId);
        console.log("changeTeacherWords", elementRef);
        if (elementRef) {
            elementRef.ref.current.changeWords(params.newWords, params.audioUrl);
        }
    }

    const changeBtnTextAndStyleAndTeacherWords = (params: {text: string, backgroundColor: string, 
        elementId: number, newWords: string, audioUrl: string}) => {
        // 这里的改变btn的text的style在BtnRegion组件里完成，ChangeTeacherWords在这里完成
        // 实际逻辑在ButtonRegion里
        const elementRef = lectureStore.elementRefs.find(eleRef => eleRef.id === params.elementId);
        console.log("changeBtnTextAndStyleAndTeacherWords", elementRef);
        if (elementRef) {
            elementRef.ref.current.changeWords(params.newWords, params.audioUrl);
        }
    }

    const callAPI = async (params: { url: string, data: any, method: string }) => {
        try {
            console.log("userID", userInfoStore.userInfoData.id);
            //现需要把data转换为json, 因为data其实是一个str
            console.log(params.url, params.data, params.method);
            // 尝试要rewrite
            let dataStr = params.data;
            if (containsVariablePattern(params.data)) {
                dataStr = rewriteText(params.data);
            }
            console.log('dataStr:', dataStr);

            const jsonData = JSON.parse(dataStr);
            console.log('jsonData:', jsonData);
            if (params.method === 'put') {
                const response = await service.put(params.url, jsonData);
                return response.data;
            } else if (params.method === 'post') {
                const response = await service.post(params.url, jsonData);
                return response.data;
            }
        } catch (error) {
            console.error('call api error:', error);
            throw error;
        }
    }

    // 其实是一个特殊的callAPI，用于更新用户信息
    const updateUserInfo = async () => {
        try {
            // 尝试要rewrite
            let dataStr = "{\"id\": \"{{@userInfoStore.userInfoData.id}}\", \"name\": \"{{nickname}}\", \"avatar_url\":\"{{avatar_url}}\", \"info\":{\"tagline\":\"{{tagline}}\"}}";
            dataStr = rewriteText(dataStr);
            console.log('dataStr:', dataStr);

            const jsonData = JSON.parse(dataStr);
            console.log('jsonData:', jsonData);
            const response = await service.put('/user_incremental', jsonData);
            if (jsonData.name) {
                userInfoStore.setUserInfoData({ name: jsonData.name });
            }
            if (jsonData.avatar_url) {
                userInfoStore.setUserInfoData({ avatarUrl: jsonData.avatar_url });
            }
            // tagline目前没有通过userInfoStore更新，因为每次进用户中心都会重新获取用户信息

            return response.data;

        } catch (error) {
            console.error('call api error:', error);
            throw error;
        }

    }

    //下载文件
    const downloadFile = async (params: { downloadType: string }) => {
        let downloadUrl = '';
        if (params.downloadType === 'cyberAvatar') {
            let avatarUrlStr = "{{avatar_url}}";
            downloadUrl = rewriteText(avatarUrlStr);
            //拿到的是一个url，直接下载
        }
        if (!downloadUrl) return;
        const a = document.createElement('a');
        a.href = downloadUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    //分享
    const share = async (params: { shareType: string }) => {
        let workName = '';
        let workType = '';
        let workInfo = {};
        if (params.shareType === 'cyberAvatar') {
            workName = '赛博头像';
            workType = 'cyberAvatar';
            let dataStr = "{\"name\": \"{{nickname}}\", \"avatar_url\":\"{{avatar_url}}\", \"tagline\":\"{{tagline}}\"}";
            dataStr = rewriteText(dataStr);
            console.log('dataStr:', dataStr);

            const jsonData = JSON.parse(dataStr);
            workInfo = jsonData;
        }
        const data = {
            user_id: userInfoStore.userInfoData.id,
            object_type: workType,
            object_id: -1,
            channel: "classroom",
            extras: {},
            create_time: 0
        };
        try {
            const res = await userShareObject(data);
            if (res.status === 'success') {
                return await generateShareInfo(params.shareType);
            }
        } catch (error) {
            console.error('Error sharing song');
        }
    }

    const generateShareInfo = async (shareType: string) => {
        let payloadData = {}
        if (shareType === 'cyberAvatar') {
            let dataStr = `{\"name\": \"{{nickname}}\", \"avatar_url\":\"{{avatar_url}}\", \"tagline\":\"{{tagline}}\",\"author_name\":\"${userInfoStore.userInfoData.name}\"}`;
            dataStr = rewriteText(dataStr);
            console.log('dataStr:', dataStr);

            const jsonData = JSON.parse(dataStr);
            payloadData = jsonData;
        }
        const concatenatedString = `${userInfoStore.userInfoData.id}${shareType}${JSON.stringify(payloadData)}`;
        const MD5 = generateMD5(concatenatedString);
        const shareLinkData = {
            id: 0,
            md5: MD5,
            user_id: userInfoStore.userInfoData.id,
            user_name: userInfoStore.userInfoData.name,
            work_type: shareType,
            work_info: payloadData,
            create_time: 0
        };
        const shareLinkRes = await createUserWork(shareLinkData);
        if (shareLinkRes.status === 'success') {
            return (`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
        } else {
            message.error('生成分享链接失败');
            return null;
        }
    };

    const functionC = () => console.log("Function C called");

    // 映射函数名到函数
    const functionMapping: Record<string, any> = {
        'openTeacherDialog': openTeacherDialog,
        'startLearn': startLearn,
        'showSpecifiedElement': showSpecifiedElement,
        'showSpecifiedElementAndHide': showSpecifiedElementAndHide,
        'navigate': navigate,
        'changeTeacherWords': changeTeacherWords,
        'changeBtnTextAndStyleAndTeacherWords': changeBtnTextAndStyleAndTeacherWords,
        'callAPI': callAPI,
        'updateUserInfo': updateUserInfo,
        'downloadFile': downloadFile,
        'share': share,
        'functionC': functionC
    };
    // 定义一个用于执行函数的方法
    type FunctionMappingKeys = keyof typeof functionMapping;

    const executeFunc = useCallback(async (funcName: string, funcParams: any) => {
        const functionToExecute = functionMapping[funcName];
        if (functionToExecute) {
            if (funcName === 'share') {
                return await functionToExecute(funcParams);
            } else {
                functionToExecute(funcParams);
            }
        } else {
            return;
        }
    }, []);
    // 生成Select选项对象的方法
    const generateSelectOptionsData = () => {
        // 初始选项
        const optionsData = [{ value: 'none', label: '     无     ' }];

        // 生成其他选项
        Object.keys(functionMapping).forEach(funcName => {
            optionsData.push({ value: funcName, label: funcName });
        });

        return optionsData;
    };


    // 根据 blockInfo 生成 elementId 的选项
    const generateElementIdOptions = () => {
        if (!lectureStore.block) return [];

        return lectureStore.block?.blockInfo.map(element => ({
            value: element.id,
            label: element.id
        }));
    };

    const generateDownloadOptions = () => {
        return [{ value: 'cyberAvatar', label: '下载头像' }];
    }

    const generateShareTypeOptions = () => {
        return [{ value: 'cyberAvatar', label: '分享头像' }];
    }

    // 返回执行方法和生成选项方法
    return { executeFunc, generateSelectOptionsData, generateElementIdOptions, generateDownloadOptions, generateShareTypeOptions };
};

export default useCommonFunction;
export type { ElementIdOption };