import { useEffect, useRef, useState } from 'react';
import styles from '../common/element.module.less';
import service from '@/services/axios';
import { cloneDeep } from "lodash";
import PropTypes from 'prop-types';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import useCommonFunction from '@/hooks/useCommonFunction';
import SelectWrongIcon from '@/assets/img/select_wrong.svg';
import SelectRightIcon from '@/assets/img/select_right.svg';
import { throttle } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
//import mixpanel from 'mixpanel-browser';
import SoundPlayingIcon from '@/assets/img/sound_playing_icon.svg';
import PlayTTSV2Icon from '@/assets/img/new_speaker.svg';
import { Tooltip, QRCode } from 'antd';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import ButtonRegionContent from '@/base/ElementData/ButtonRegionContent';
import { BlockTraceData } from '@/base/BlockData';

interface ButtonRegionProps extends IntrinsicElementProps<ButtonRegionContent> {
    btnStatus?: number;
}

const ButtonRegion: React.FC<ButtonRegionProps> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    btnStatus = 0 }) => {
    const [isBtnValuePlaying, setIsBtnValuePlaying] = useState(false);
    const { viewportStore, lectureStore } = useStores();
    const currentBlock = lectureStore.learnedBlockList[lectureStore.currentBlockIndex];
    const { scaleRatio } = viewportStore;
    const btnClickSoundRef = useRef<HTMLAudioElement | null>(null);
    const playBtnValueRef = useRef<HTMLAudioElement | null>(null);
    const { executeFunc } = useCommonFunction();
    const [shareUrl, setShareUrl] = useState(null);//绑定了分享函数才会用到
    const [qrLoading, setQrLoading] = useState(false);
    const [showQRCode, setShowQRCode] = useState(true);
    const [btnText, setBtnText] = useState(elementData.content.value || "");
    const [btnTextColor, setBtnTextColor] = useState(elementData.content.fontColor || "#FFFFFF");
    const boxShadow = `${elementData.content?.shadowX || 0}px 
                        ${elementData.content?.shadowY || 0}px 
                        ${elementData.content?.shadowBlur || 0}px 
                        ${elementData.content?.shadowSpread || 0}px 
                        ${elementData.content?.shadowColor || '#000000'}`;

    const border = `${elementData.content.borderWidth || 0}px solid ${elementData.content.borderColor || '#000000'}`;

    const selectedStyle = {
        background: '#D7ECFF',
        border: '2px solid #51ADFF',
        boxShadow: '0px 2px 0px 0px #2F88D7',
    }
    const selectedRightStyle = {
        background: '#D5FFF5',
        border: "2px solid #01D47C",
        boxShadow: "0px 2px 0px 0px #0D9A5E",

    }
    const selectedWrongStyle = {
        background: '#FFD5D7',
        border: "2px solid #FF626C",
        boxShadow: "0px 2px 0px 0px #CB1E29",
    }
    const borderAndShadow = () => {
        if (btnStatus === 1) {
            return selectedStyle;
        } else if (btnStatus === 2) {
            return selectedRightStyle;
        } else if (btnStatus === 3) {
            return selectedWrongStyle;
        } else {
            return {
                border: border,
                boxShadow: boxShadow,
                backgroundColor: elementData.content.backgroundColor
            };
        }
    }
    // 播放按钮内容
    const playBtnValue = () => {
        if (elementData.content?.valueAudioUrl) {
            playBtnValueRef.current = new Audio(elementData.content.valueAudioUrl);
            playBtnValueRef.current.play().then(() => {
                setIsBtnValuePlaying(true);
            })
                .catch((error) => {
                    console.error('Error playing audio:', error);
                });
            playBtnValueRef.current.onended = () => {
                setIsBtnValuePlaying(false);
            };
        }
    }
    //暂停播放按钮内容
    const pauseBtnValue = () => {
        setIsBtnValuePlaying(false);
        playBtnValueRef.current?.pause();
    }

    // 点击按钮时音效
    const playClickBtnSound = () => {
        const audio = btnClickSoundRef.current;
        if (!audio) {
            return;
        }

        audio.currentTime = 0;
        audio.play().then(() => {
            console.log('Audio played successfully');
        })
            .catch((error) => {
                console.error('Error playing audio:', error);
            });

        setTimeout(() => {
            if (audio.duration > 1) {
                audio.pause();
                audio.currentTime = 0;
            }
        }, 1000); // 最多播放两秒
    };

    // 节流处理，防止连续点击
    const throttledPlaySound = throttle(playClickBtnSound, 300); // 每3秒最多触发一次

    //更新后端数据blockTrace
	const updateBlockTrace = async (newBlockTraceData: BlockTraceData) => {
		try {
			await service.put('/block_trace', newBlockTraceData.toJSON());
		} catch (error) {
			console.log('error', error)
		}
	};

    const handleClick = async () => {
        // if (import.meta.env.PROD) {
        //     mixpanel.track('ButtonRegion-handleClick',
        //         { 'btnValue': elementData.content.value }
        //     );
        // }
        if (elementData?.content?.clickSoundUrl) {
            throttledPlaySound();
        }
        if (elementData?.content?.onclickFunc?.funcName === 'share') {
            setShowQRCode(true); // 显示二维码  
            setQrLoading(true);
            let shareUrl = await executeFunc(elementData.content.onclickFunc?.funcName,
                elementData.content.onclickFunc?.funcParams);
            setQrLoading(false);
            setShareUrl(shareUrl);
        } else {
            setShowQRCode(false); // 点击其他功能时隐藏二维码 
            if (!elementData.content.onclickFunc) return;
            
            // 优先处理changeBtnTextAndStyle
            if(elementData && elementData.content.onclickFunc?.funcName === 'changeBtnTextAndStyleAndTeacherWords') {
                setBtnText(elementData.content.onclickFunc.funcParams.text);
                setBtnTextColor(elementData.content.onclickFunc.funcParams.textColor);
                const newElementData = cloneDeep(elementData);
                newElementData.content.value = elementData.content.onclickFunc.funcParams.text;
                newElementData.content.fontColor = elementData.content.onclickFunc.funcParams.textColor;
                lectureStore.updateElement(currentBlock.id, elementData.id, newElementData.content);
                executeFunc(elementData.content.onclickFunc!.funcName,
                    elementData.content.onclickFunc?.funcParams);
                const newBlockTraceData = lectureStore.findBlockById(currentBlock.id);
                //console.log("updateBlockTrace", newBlockTraceData);
                updateBlockTrace(newBlockTraceData!);
            } else {
                executeFunc(elementData.content.onclickFunc!.funcName,
                    elementData.content.onclickFunc?.funcParams);
            }
        }
    };

    useEffect(() => {
        btnClickSoundRef.current = new Audio(elementData?.content?.clickSoundUrl);
    }, [elementData?.content?.clickSoundUrl]);

    useEffect(() => {
        return () => {
            if (playBtnValueRef.current) {
                playBtnValueRef.current.pause();
                playBtnValueRef.current = null;
            }
        }
    }, []);


    return (<BaseDragableElement elementData={elementData}
        isEditable={isEditable}
        handleFocusItem={handleFocusItem}
        handleResize={handleResize}
        handleDragStop={handleDragStop}
        handleDelete={handleDelete}>
        <div
            style={{ ...commonStyle }}
            onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
            className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            {...(isEditable && { 'data-findparent': 'findparent' })}
        >
            <Tooltip
                color='white'
                trigger='click'
                title={
                    showQRCode && (
                        <QRCode
                            value={shareUrl || ''} size={150}
                            status={qrLoading ? "loading" : "active"}
                        />
                    )}
            >
                <button
                    className={styles.button}
                    style={{
                        padding: `${scaleRatio * 4}px ${scaleRatio * 15}px`,
                        fontSize: `${scaleRatio * elementData.content.fontSize}px`,
                        borderRadius: `${elementData.content.borderRadius}px`,
                        color: btnTextColor,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: elementData.content.bulletEnabled ? 'flex-start' : 'center',
                        boxSizing: 'border-box',
                        ...borderAndShadow()
                    }}
                    onClick={handleClick}
                >

                    {elementData.content.bulletEnabled && <span
                        style={{
                            width: `${scaleRatio * elementData.content.bulletCircleRadius}px`, // 圆的直径
                            height: `${scaleRatio * elementData.content.bulletCircleRadius}px`, // 圆的直径
                            borderRadius: '50%', // 使它成为一个圆
                            backgroundColor: `${elementData.content.bulletCircleColor}`, // 圆的颜色
                            marginRight: '10px', // 圆和文字之间的间距
                            display: 'flex', // 使内容在圆内居中
                            justifyContent: 'center', // 水平居中
                            alignItems: 'center', // 垂直居中
                            color: `${elementData.content.bulletLetterColor}`, // 字母的颜色
                            fontSize: `${scaleRatio * elementData.content.bulletLetterSize}px` // 字母的字体大小
                        }}
                    >
                        {elementData.content.bulletLetter}
                    </span>}
                    {btnText}
                    {btnStatus === 2 && <img src={SelectRightIcon} alt="select"
                        style={{
                            width: `${scaleRatio * 30}px`, height: `${scaleRatio * 30}px`,
                            position: 'absolute', right: '10px', top: '50%',
                            transform: 'translateY(-50%)'
                        }} />}
                    {btnStatus === 3 && <img src={SelectWrongIcon} alt="select"
                        style={{
                            width: `${scaleRatio * 30}px`, height: `${scaleRatio * 30}px`,
                            position: 'absolute', right: '10px', top: '50%',
                            transform: 'translateY(-50%)'
                        }} />}
                </button>
            </Tooltip>
            {elementData.content.showAudioIcon &&
                <img
                    src={isBtnValuePlaying ? SoundPlayingIcon : PlayTTSV2Icon}
                    className={styles.textBtnPlayingIcon}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (isBtnValuePlaying) {
                            pauseBtnValue();
                        } else {
                            playBtnValue();
                        }
                    }}
                />}
        </div>
    </BaseDragableElement>);
};

export default observer(ButtonRegion);
