import { ElementContent } from './ElementData';

class TreasureHuntRegionContent extends ElementContent {
    gameType: string;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 520,
        height = 520,
        gameType = "treasureHunt"
    }: Partial<TreasureHuntRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'gameType'];
        this.gameType = gameType;
    }
};

export default TreasureHuntRegionContent;