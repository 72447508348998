import React, { useEffect, useRef, useState } from 'react';
import styles from "./rollingBallRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import RollingBallRegionContent from '@/base/ElementData/RollingBallRegionContent';
import { useMediaQuery } from 'react-responsive';

const RollingBallRegion: React.FC<IntrinsicElementProps<RollingBallRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isPad = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1280px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1281px)' });

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [angleIndex, setAngleIndex] = useState(0);
    const angles = [20, 30, 45, 60, 75];

    const g = 9.8;
    const frictionCoefficient = 0.1;
    let ballSpeed = 0;
    let ballPosition = useRef({ x: 0, y: 0 });
    let startTime: number | null = null;
    const [timeDisplay, setTimeDisplay] = useState('');

    const scaleFactor = isPad ? 0.78 : 1;

    const drawSlope = (angle: number) => {
        const canvas = canvasRef.current;
        if (!canvas) return 0;

        const ctx = canvas.getContext('2d');
        if (!ctx) return 0;

        const baseHeight = 300;
        const height = baseHeight * scaleFactor;
        const radian = angle * (Math.PI / 180);
        const width = height / Math.tan(radian);

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const platformX = 0;
        const platformY = 100 * scaleFactor;
        const platformWidth = 30 * scaleFactor;
        const platformHeight = 300 * scaleFactor;
        ctx.fillStyle = 'green';
        ctx.fillRect(platformX, platformY, platformWidth, platformHeight);

        const slopeStartX = platformX + platformWidth;
        const slopeBaseY = 400 * scaleFactor;

        ctx.beginPath();
        ctx.moveTo(slopeStartX, slopeBaseY);
        ctx.lineTo(slopeStartX + width, slopeBaseY);
        ctx.lineTo(slopeStartX, platformY);
        ctx.closePath();

        ctx.fillStyle = 'green';
        ctx.fill();

        ctx.fillStyle = 'black';
        ctx.font = `${20 * scaleFactor}px Arial`;
        ctx.fillText(`当前坡度：${angle}°`, 10 * scaleFactor, 30 * scaleFactor);

        return width;
    };

    const drawBall = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.beginPath();
        ctx.arc(ballPosition.current.x, ballPosition.current.y, 10 * scaleFactor, 0, Math.PI * 2);
        ctx.fillStyle = 'red';
        ctx.fill();
        ctx.closePath();
    };

    const calculateAcceleration = (angle: number) => {
        const radian = angle * (Math.PI / 180);
        const normalForce = Math.cos(radian);
        const frictionForce = frictionCoefficient * normalForce * g;
        const gravityForce = Math.sin(radian) * g;
        return gravityForce - frictionForce;
    };

    const slideBall = (timestamp: number) => {
        if (!startTime) startTime = timestamp;
        const elapsedTime = ((timestamp - startTime) / 1000);

        const acceleration = calculateAcceleration(angles[angleIndex]);
        const newSpeed = ballSpeed + acceleration * elapsedTime;
        const radian = angles[angleIndex] * (Math.PI / 180);

        const width = drawSlope(angles[angleIndex]);
        const slopeStartX = 30 * scaleFactor;
        const slopeStartY = 90 * scaleFactor;

        const newX = ballPosition.current.x + newSpeed * elapsedTime;
        const newY = slopeStartY + (newX - slopeStartX) * Math.tan(radian);

        ballSpeed = newSpeed;
        ballPosition.current = { x: newX, y: newY };

        drawBall();

        const slopeEndX = slopeStartX + width;
        if (newX >= slopeEndX) {
            const finishTime = elapsedTime.toFixed(2);
            const canvas = canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.fillStyle = 'black';
                    ctx.font = `${20 * scaleFactor}px Arial`;
                    ctx.fillText(`耗时：${finishTime}秒`, 200 * scaleFactor, 30 * scaleFactor);
                }
            }
            return;
        }

        requestAnimationFrame(slideBall);
    };

    useEffect(() => {
        const initialX = 30 * scaleFactor;
        const initialY = 90 * scaleFactor;
        ballPosition.current = { x: initialX, y: initialY };
        drawSlope(angles[angleIndex]);
        drawBall();
    }, [angleIndex, scaleFactor]);

    const handleAdjustSlope = () => {
        const newAngleIndex = (angleIndex + 1) % angles.length;
        setAngleIndex(newAngleIndex);
        ballPosition.current = { x: 30 * scaleFactor, y: 90 * scaleFactor };
        ballSpeed = 0;
        setTimeDisplay('');
    };

    const handleStartSlide = () => {
        ballPosition.current = { x: 30 * scaleFactor, y: 90 * scaleFactor };
        ballSpeed = 0;
        startTime = null;
        setTimeDisplay('');
        requestAnimationFrame(slideBall);
    };

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.rollingBallBox}>
                    <canvas
                        ref={canvasRef}
                        className={styles.bollCanvas}
                        width={900 * scaleFactor}
                        height={400 * scaleFactor}
                    ></canvas>
                    <div className={styles.buttonGroup}>
                        <button onClick={handleAdjustSlope}>
                            {`调整坡度为${angles[(angleIndex + 1) % angles.length]}°`}
                        </button>
                        <button onClick={handleStartSlide}>开始滑动</button>
                    </div>
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default RollingBallRegion;