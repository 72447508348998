import { ElementContent } from './ElementData';

class NeuronCalculatorRegionContent extends ElementContent {

    constructor({
        positionX = 0,
        positionY = 0,
        width = 850,
        height = 450
    }: Partial<NeuronCalculatorRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height','zIndex'];
    }
};

export default NeuronCalculatorRegionContent;